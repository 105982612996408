import Cookies from 'js-cookie'
import u from 'umbrellajs'

export default {
  init: OmnitureTracking,
  trackOmniture: bindTracking,
  trackCustomOmnitureEvent: trackCustomEvent,
}

function OmnitureTracking(){

  // Check for omniture, if there isn't any we can bail out now
  const $tracking = u('*[data-omniture-tracking]')
  if($tracking.length === 0){ return; }

  setupTracking()
  bindTracking()
}

export function bindTracking(){
  const $tracking = u('*[data-omniture-tracking]:not([data-omniture-tracked])')
  $tracking.each(function(){
    const omData = JSON.parse(this.data('omniture-tracking'))
    this.data('omniture-tracked', true)
    if(omData.pageView === true){
      setProps(omData)
      executeTrack()
    }
    if(omData.clickTrack){
      //
      this.on('click', function(){
        if(omData.eVar && omData.event){
          trackCustomEvent(omData.event, omData.value || '', omData.eVar)
          executeTrack('click')
        } else {
          // Not sure if this is being used, but its how the logic worked before
          omData.products = JSON.parse(this.data('product-handle'))
          setProps(omData)
          executeTrack()
        }
      })
    }
    if(omData.clickStoreEvar){
      this.on('click', function(){
        sessionStorage.setItem(omData.eVar, omData.value)
      })
    }
  })
}

function setupTracking(){
  s.linkTrackVars = 'events,eVar5,eVar6,eVar7,eVar11,eVar12,eVar13,eVar23,prop3,prop8,prop36,prop37,products';
  s.linkTrackEvents = 'event37,event38,event27,event28,event216,prodView,scView,scCheckout,purchase';
}

function setProps(omData) {
  s.eVar5 = Cookies.get('omVar5') || ''
  s.eVar6 = Cookies.get('omVar6') || ''
  s.eVar7 = Cookies.get('omVar7') || ''
}

function executeTrack( name = 'subscriptions' ){
  s.tl(this, 'o',  name)
}

function trackCustomEvent(eventId, eVar, key = 'eVar5'){
  setupTracking()
  s.events = eventId
  s[key] = eVar
  Cookies.set(key.replace('eVar', 'omVar'), eVar)
  executeTrack()
}
