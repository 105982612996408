import { menunav, menunavEl, mobilemenuEl } from './constants';

//menu template
const menu_navbar = (navname, href) => `<a class="open_menu" href=${(href != '') ? `'${href}'` : '#'}>${navname}</a>`;

const menu_navbar_list = (template) => `<li>${template}</li>`;

const menu_navbar_template = (template, fragment) => {
  return `<div id="item_d1" class="menu-item">
      <div class="row">
          <div class="column column-bg-color" id="grid-columns">${template}</div>
          <div class="column">${fragment}</div>
      </div>
    </div>`;
}

const sub_menu_template = (template) => {
  return `<div class="item">
    <a href="${template.href}">
        <h3>${template.heading}</h3>
        <hr>
        <p>${template.description}</p>
    </a>
  </div>`;
}

//mobile sub menu template
const mobile_submenu_template = (template) => {
  return `<li><a href="${template.href}">${template.heading}</a></li>`;
}

//mobile main menu template
const mobile_template = (navname, href, template) => {
  return `<div class="menu-item-holder">
  ${href != '' ?
      `<a class="menu-item-nav" href="${href}">
        <div class="menu-header-block">
          <h5 class="submenu-item-angle">${navname}</h5>
        </div>
      </a>` :
      `<div class="menu-header-block">
        <h5>${navname}</h5>
        <span class="arrow-down"></span>
      </div>${template}`}
    </div>`;
}

//mobile menu template
const mobile_menu_template = (template) => {
  return `<ul class="menu-item-links">${template}</ul>`;
}

//main login for menu
export default function menu(selector) {
  if (selector) {
    let menunavbar_list = '';
    for (const items in selector) {
      let mobilemenuContent = ''; let mainmenuli = '';
      mainmenuli += menu_navbar(items, selector[items].href);
      if (selector[items].dropdown) {
        let submenuContent = ''; let mobilesubmenuContent = '';
        for (const submenuitems in selector[items].sub_menu.data) {
          submenuContent += sub_menu_template(selector[items].sub_menu.data[submenuitems]);
          mobilesubmenuContent += mobile_submenu_template(selector[items].sub_menu.data[submenuitems]);
        }
        mainmenuli += menu_navbar_template(submenuContent, selector[items].fragment);
        mobilemenuContent += mobile_menu_template(mobilesubmenuContent);
      }
      menunavbar_list += menu_navbar_list(mainmenuli);
      mobilemenuEl.insertAdjacentHTML('beforebegin', mobile_template(items, selector[items].href, mobilemenuContent))
    }
    menunavEl.insertAdjacentHTML('afterbegin', menunavbar_list);
  }
  menunav.style.display = 'block';
}
