import axios from 'axios';

import { lightcandle, LIGHT_CANDLE_EVENT_NAME } from './constants';

export default class LightCandle {
  constructor() {
    this.debounceTimer = null
    this.init();
  }

  init() {
    if (lightcandle.candleEL) {
      lightcandle.candleEL.addEventListener('click', event => this.debouncedLightCandleEvent(event), false)

      for (let value of lightcandle.togglecontentEl) {
        value.addEventListener('click', event => this.sfTextEvent(event), false)
      }

      const id = lightcandle.candleEL.getAttribute("data-postid");

      if (this.cookieUserData(`lit_${id}`) === 'true') {
        this.litCandle();
        this.toggleCandle(id);
        this.toggleCandleContent();
      }
    }
  }

  // to throw user facing errors
  errorFunction = function (error) {
    throw new Error(`Candle Error: "${error}"`)
  }

  //trigger sf-txt click event
  sfTextEvent(event) {
    lightcandle.candleEL.click();
  }

  // Returns the current `post id` from a cookie
  cookieUserData(name) {
    return window.Cookies.get(name);
  }

  debouncedLightCandleEvent(event) {
    // Clear the previous timer if it exists
    if (self.debounceTimer) {
      clearTimeout(self.debounceTimer)
    }

    // Set a new timer
    self.debounceTimer = setTimeout(() => {
      this.lightCandleEvent(event)
    }, 300) // 300 milliseconds
  }

  lightCandleEvent(event) {

    if (lightcandle.candleEL && event.target.classList.contains(lightcandle.HAS_CANDLE)) {
      this.toggleCandleContent(lightcandle.lit ? "Unlighting <br> a candle" : "Lighting <br> a candle")

      const state = {
        id: event.target.getAttribute("data-postid"),
        token: lightcandle.recaptchaToken
      }
      // If we have a token already then reset it
      if (state.token.value != '') {
        grecaptcha.reset(window.recaptchaCandle)
      }

      // Submit a recaptcha click
      lightcandle.submitEl.click()

      // Wait for a recaptcha token
      const recaptchaTokenWait = new Promise((resolve) => {
        let interval = setInterval(() => {
          if (grecaptcha.getResponse(window.recaptchaCandle))
            resolve(interval)
        })
      })

      // Load the recaptcha state
      recaptchaTokenWait.then(() => {
        this.load(state)
      }, () => {
        this.toggleCandleContent()
        return this.errorFunction('There was an error while validating recaptcha, please try again later')
      })
    }
  }

  // trigger custom event for light a candle in full story
  triggerCandleFullStoryEvent() {
    if (typeof FS !== 'undefined') {
      FS('trackEvent', {
        name: LIGHT_CANDLE_EVENT_NAME,
        properties: {
          candle_lit: lightcandle.lit, // this will be inferred as a boolean
        }
      })
    }
  }

  load(state) {
    if (lightcandle.submitEl != null && state.token.value != '') {
      this.sendRequestToBackendAndUpdateButton(state)
    } else {
      return this.errorFunction('There was an error while validating recaptcha, please try again later');
    }
  }

  async sendRequestToBackendAndUpdateButton(params) {
    try {
      const { data } = await axios.post(
        window.rnn.site.tributes + lightcandle.URL,
        {
          'pk': parseInt(params.id),
          'flag': !lightcandle.lit,
          'g-recaptcha-response': params.token.value
        }
      );
      if (data) {
        this.litCandle();
        this.toggleCandle(params.id);
        this.trackAdobeEvents()
        this.triggerCandleFullStoryEvent()
        window.Cookies.set(`lit_${params.id}`, lightcandle.lit, { path: '/', expires: 34560000, secure: true });
      } else {
        return this.errorFunction('There was an error attempting to light a candle, please try again later');
      }
    } catch (err) {
      return this.errorFunction('There was an error attempting to light a candle, please try again later')
    }
  }

  toggleCandle(id) {
    if (!lightcandle.lit && (lightcandle.candleEL.classList.contains(lightcandle.HAS_CANDLE_LIT))) {
      for (let i = 0, len = lightcandle.candleCount.length; i < len; i++) {
        lightcandle.candleCount[i].textContent = parseInt(lightcandle.candleCount[i].textContent) + 1;
      }
    } else {
      this.removeLitCandle();
      window.Cookies.set(`lit_${id}`, lightcandle.lit, { path: '/', expires: 0, secure: true });
    }

    lightcandle.lit = !lightcandle.lit;
    this.toggleCandleContent();
  }

  litCandle() {
    lightcandle.candleEL.classList.add(lightcandle.HAS_CANDLE_LIT);
  }

  removeLitCandle() {
    for (let i = 0, len = lightcandle.candleCount.length; i < len; i++) {
      if (parseInt(lightcandle.candleCount[i].textContent) != 0) {
        lightcandle.candleCount[i].textContent = parseInt(lightcandle.candleCount[i].textContent) - 1;
      }
    }
    lightcandle.candleEL.classList.remove(lightcandle.HAS_CANDLE_LIT);
  }

  toggleText() {
    const toggleTxt = lightcandle.lit ? "You lit " : "Light "
    return `${toggleTxt} <br> a candle`;
  }

  trackAdobeEvents() {
    if (lightcandle.adobeTrackerEL) {
      const trackerTxt = lightcandle.lit ? "light-candle" : "unlight-candle"
      for (let i = 0, len = lightcandle.adobeTrackerEL.length; i < len; i++) {
        lightcandle.adobeTrackerEL[i].setAttribute('data-tgev-label', `button|${trackerTxt}`)
      }
    }
  }
  toggleCandleContent(content = null) {
    for (let i = 0, len = lightcandle.togglecontentEl.length; i < len; i++) {
      lightcandle.togglecontentEl[i].innerHTML = content || this.toggleText();
    }
  }
}
