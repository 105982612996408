import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import typeahead from "typeahead.js";
import Bloodhound from "bloodhound-js";

$(function () {
  // overlay menu open
  $("[class*='tributes'] header .hamburger").on("click", () => $('#overlay-menu').css('display', 'block'))

  // overlay menu close
  $(".closeIcon").on("click", () => $('#overlay-menu').css('display', 'none'))

  // recent notices
  $(document).on('focus', '.searchdropdown', function () {
    $('[data-search-form]').parent().css({ 'z-index': '99' });
  })
  $(document).on('blur', '.searchdropdown', function () {
    $('[data-search-form]').parent().removeAttr("style");
  });

  // homepage fd dropdown
  $(".fd-state-dropdown").on({
    "change": function () {
      $(this).blur();
    },
    "blur": function () {
      $(this).parent().parent().removeClass('active');
    },
    "focus": function () {
      $(this).parent().parent().addClass('active');
    }
  });

  $(document).on('click', '.mobile-menu .menu-item-holder .menu-header-block', function () {
    $(this).parent().toggleClass('active');
  });

  $('.nav-item ul li').on('click mouseover', function (e) {
    $('.nav-item ul li').children().removeClass("active");
    $(this).children().toggleClass("active");
    $('body').on('click mouseover', function () {
      if (!$('.nav-item ul li div.menu-item').css('display') == 'block') {
        $(this).children().toggleClass("active");
      } else {
        $('.nav-item ul li').children().removeClass("active");
        $('body').off('mouseover');
      }
    })
    e.stopPropagation();
  });

  $('.mb-search').on('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    $('.refine-search-filter').css('display', 'block');
    $('#header, .mb-search').css('display', 'none');
    document.body.style.height = '100%';
    document.body.style.overflow = "hidden";
  });

  $("span#refine-close").on("click", function () {
    $('.refine-search-filter').css('display', 'none');
    $('#header').css('display', 'block');
    $('.mb-search').css('display', 'flex');
    document.body.style.height = '';
    document.body.style.overflow = '';
  });

  let lastwidth = $(window).width();
  $(window).on('resize', function () {
    if ($(this).width() !== lastwidth) {
      lastwidth = $(this).width();
      if ($(this).width() > 767) {
        if ($('.refine-search-filter-mobile .refine-search-filter').length) {
          $('.refine-search-filter-mobile .refine-search-filter').css('display', 'none');
        } else {
          $('.refine-search-filter').css('display', 'block');
        }
        $('.mb-search').css('display', 'none');
        $('#header').css('display', 'block');
        document.body.style.height = '';
        document.body.style.overflow = "";
      } else if ($(this).width() <= 767) {
        $('.refine-search-filter').css('display', 'none');
        $('.mb-search').css('display', 'flex');
        $('#header').css('display', 'block');
      }
    }
  });
});

export default class Header {
  constructor(searchInput, formExpand, mobileSearchTitle, mobileSearchInput, closeMobileForm, searchFormEl, searchIconEl, documentBodyEl) {
    this.searchInput = searchInput;
    if (!this.searchInput) {
      return;
    }
    this.baseUrl = window.rnn.site.tributes || '/';
    this.formExpand = formExpand;
    this.mobileSearchTitle = mobileSearchTitle;
    this.mobileSearchInput = mobileSearchInput;
    this.closeMobileForm = closeMobileForm;
    this.searchFormEl = searchFormEl;
    this.searchIconEl = searchIconEl;
    this.documentBodyEl = documentBodyEl;
    this.keywordInputEl = this.searchFormEl.querySelectorAll('input[name="keywords"]')[0];
    this.keywordInputFDEl = this.searchFormEl.querySelectorAll('input[name="directors"]')[0];
    this.classificationSelectEl = this.searchFormEl.querySelectorAll('select[name="category"]')[0];

    this.$locationEl = $('#locations');
    this.$sidebarlocationEl = $('#sidebarlocation');
    this.$multiplelocations = $('#locations, #sidebarlocation');
    this.$directorsEl = $('#funeral_directors');
    this.$directorsRfEl = $('#funeral_directors_rf');
    this.$localityInputEl = $('#typeahead-locality');
    this.$sidebarlocalityInputEl = $('#sidebar-typeahead-locality');
    this.$directorInputEl = $('#typeahead-directors');
    this.refinesearchformEl = document.getElementById('refine-search-filter');
    if (document.querySelector('.funeral_directors_rf') !== null) {
      this.$directorInputRfEl = $('#typeahead-directors-rf');
      this.initTypeaheadHeader('#funeral_directors_rf');
    }
    this.locationElClose = this.searchFormEl.querySelector("[data-clear-input]") || '';
    this.sidebarlocationElClose = (this.refinesearchformEl != null) ? this.refinesearchformEl.querySelector("[data-clear-sidebar-input]") : '';

    this.initTypeaheadHeader('#funeral_directors');
    this.loadListener();
    this.initLocation();
  }

  loadListener() {
    const _params = {
      element: this.$locationEl,
      clearevent: { 'location': this.$locationEl, 'locationinput': this.$localityInputEl },
      closelocation: { 'closeEl': this.locationElClose, 'locationEle': this.$locationEl, 'inputlocationEle': this.$localityInputEl },
      closesidebar: {}
    };

    // window.addEventListener('resize', event => this.onWindowResize(event));
    // this.searchInput.addEventListener("click", event => this.toggleSearchForm(true));
    // this.closeMobileForm.addEventListener("click", event => this.toggleSearchForm(false));

    this.$locationEl.bind('typeahead:select', (ev, suggestion) => {
      this.$localityInputEl.val(suggestion.slug);
    });

    this.$sidebarlocationEl.bind('typeahead:select', (ev, suggestion) => {
      this.$sidebarlocalityInputEl.val(suggestion.slug);
    });

    this.$directorsEl.bind('typeahead:autocomplete typeahead:select', (ev, suggestion) => {
      this.$directorInputEl.val(suggestion.id);
    });

    this.$directorsRfEl.bind('typeahead:autocomplete typeahead:select', (ev, suggestion) => {
      this.$directorInputRfEl.val(suggestion.id);
    });

    this.searchWithSelector(_params);

    if (document.querySelector('.funeral_directors_rf') !== null) {
      _params.element = this.$directorsRfEl;
      _params.clearevent.location = this.$directorsRfEl;
      _params.clearevent.locationinput = this.$directorInputRfEl;
      this.searchWithSelector(_params);

      this.$directorsRfEl[0].addEventListener("input", () => {
        this.conditionallyHideClearIcon();
      });
    }

    if (document.querySelector('.funeral_director') !== null) {
      this.$directorsEl[0].addEventListener("input", () => {
        this.conditionallyHideClearIcon();
      });

      this.$directorsEl.on('search', event => {
        const params = { 'location': this.$directorsEl, 'locationinput': this.$directorInputEl };
        this.conditionallyClearLocation(params);
      });
    }

    if (this.locationElClose) {
      this.locationEventlistener(_params.closelocation);
    }

    if (this.sidebarlocationElClose) {
      const closesidebar = { 'closeEl': this.sidebarlocationElClose, 'locationEle': this.$sidebarlocationEl, 'inputlocationEle': this.$sidebarlocalityInputEl };
      this.locationEventlistener(closesidebar);
    }

    this.conditionallyHideClearIcon();

    if (this.$locationEl && this.$locationEl[0] !== undefined && this.$locationEl[0] !== null) {
      this.$locationEl[0].addEventListener("input", () => {
        this.conditionallyHideClearIcon();
      });
    }

    if (this.$sidebarlocationEl && this.$sidebarlocationEl[0] !== undefined && this.$sidebarlocationEl[0] !== null) {
      this.sidebarHideCleariconOnInput(this.$sidebarlocationEl[0]);
    }
  }

  searchWithSelector(selectors) {
    selectors.element.on('search', event => {
      this.conditionallyClearLocation(selectors.clearevent);
    });
  }

  conditionallyClearLocation(ele) {
    if (!ele.location.val()) {
      ele.locationinput.val("");
    }
  }

  locationEventlistener(selector) {
    selector.closeEl.addEventListener("click", () => {
      selector.locationEle.val("");
      selector.inputlocationEle.val("");
      this.conditionallyHideClearIcon();
    });
  }

  sidebarHideCleariconOnInput(elem) {
    elem.addEventListener("input", () => {
      this.conditionallyHideClearIcon();
    });
  }

  conditionallyHideClearIcon(e) {
    let clearicon = this.locationElClose || '';
    let clearsideicon = this.sidebarlocationElClose || '';
    const locationparams = { 'element': clearicon, 'elementEl': this.$locationEl };
    this.showclearicon(locationparams);

    const sidebarparams = { 'element': clearsideicon, 'elementEl': this.$sidebarlocationEl };
    this.showclearicon(sidebarparams);
  }

  showclearicon(params) {
    if (params.element) {
      params.element.style.display = (params.elementEl.val()) ? 'block' : 'none';
    }
  }

  onWindowResize(e) {
    if (window.innerWidth > 767) {
      this.toggleSearchForm(false);
    }
  }

  toggleSearchForm(show) {
    if (show && window.innerWidth < 767) {
      this.formExpand.classList.add("display-form");
      this.mobileSearchTitle.classList.add("flex-center");
      this.mobileSearchInput.classList.add("input-always-show-open");
      this.searchFormEl.classList.add("min-form-height");
      this.searchInput.classList.remove("js-toggle-search-style");
      this.searchIconEl.classList.remove("js-search-icon");
      this.documentBodyEl.classList.add("search-showing");
    } else if (!show) {
      this.formExpand.classList.remove("display-form");
      this.mobileSearchTitle.classList.remove("flex-center");
      this.mobileSearchInput.classList.remove("input-always-show-open");
      this.searchFormEl.classList.remove("min-form-height");
      this.searchInput.classList.add("js-toggle-search-style");
      this.searchIconEl.classList.add("js-search-icon");
      this.documentBodyEl.classList.remove("search-showing");
    }
  }

  initTypeaheadHeader(elementId) {
    // Instantiate the Bloodhound suggestion engine
    const funeral_directors = new Bloodhound({
      datumTokenizer: datum => Bloodhound.tokenizers.whitespace(datum.value),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        wildcard: '%QUERY',
        url: this.baseUrl + 'fd-typeahead/?q=',
        replace: function (url, query) {
          let val = $(elementId).val();
          return url + val;
        },
        transform: response => {
          const fd_data = [];
          $.each(response, function (index, data) {
            fd_data.push({ "name": index, "id": data });
          })
          return fd_data.map(function (suggestion) {
            suggestion.name = suggestion.name;
            suggestion.id = suggestion.id;
            return suggestion;
          });
        }
      }
    });

    funeral_directors.initialize();

    $(elementId).typeahead({
      minLength: 1,
      maxLength: 30,
      highlight: true,
      hint: false,
      classNames: {
        input: 'Typeahead-input',
        hint: 'Typeahead-hint',
        selectable: 'Typeahead-selectable'
      }
    },
      {
        name: 'fd',
        display: 'name',
        source: funeral_directors.ttAdapter(),
      });
  }

  initLocation() {
    const locationUrl = this.baseUrl + 'location/typeahead.json?query=%QUERY&limit=25&types=po&return=slug&format=sensible&return_type=list';
    let locations = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: locationUrl,
        wildcard: '%QUERY',
        transform: function (response) {
          // have that already indexed when the requirements are established
          return response.map(function (suggestion) {
            suggestion.sensible_address = suggestion.sensible_address.replace(/, Australia/g, '');
            suggestion.sensible_address = suggestion.sensible_address.replace(/Queensland/g, 'QLD');
            suggestion.sensible_address = suggestion.sensible_address.replace(/New South Wales/g, 'NSW');
            suggestion.sensible_address = suggestion.sensible_address.replace(/Northern Territory/g, 'NT');
            suggestion.sensible_address = suggestion.sensible_address.replace(/Australian Capital Territory/g, 'ACT');
            suggestion.sensible_address = suggestion.sensible_address.replace(/South Australia/g, 'SA');
            suggestion.sensible_address = suggestion.sensible_address.replace(/Tasmania/g, 'TAS');
            suggestion.sensible_address = suggestion.sensible_address.replace(/Western Australia/g, 'WA');
            return suggestion;
          });
        }
      },
    });
    this.$multiplelocations.typeahead({
      minLength: 2,
      highlight: true,
      hint: false,
      classNames: {
        input: 'Typeahead-input',
        hint: 'Typeahead-hint',
        selectable: 'Typeahead-selectable'
      }
    },
      {
        name: 'location',
        display: 'sensible_address',
        source: locations,
        limit: 8,
      });
  }
}
