import u from 'umbrellajs'
import { isMobile } from '~/common/js/Responsive'

export const cleanPhone = phone => phone.replace(/[^0-9]/g, "")

export default function initB64() {
  u('[data-b64]').each(function(element){
    element.innerHTML = atob(element.getAttribute('data-b64'))
  })

  u('[data-click-b64]').each(function(element){
    const $ele = u(element)
    const originalListener = function(){
      const decryptedValue = atob($ele.data('click-b64'))
      $ele.html(decryptedValue)
      // Hidden phone numbers will become clickable on mobile
      if(isMobile() && $ele.attr('class').includes('hiddenPhone')){
        $ele.off('click', originalListener)
          .handle('click', function(){
            window.location.href = 'tel://' + cleanPhone(decryptedValue)
          })
      }else if(isMobile() && $ele.attr('class').includes('hiddenEmail')){
        $ele.off('click', originalListener)
        .handle('click', function(){
          window.location.href = 'mailto://'+decryptedValue;
        })
      }
    }
    $ele.on('click', originalListener)
  })
  u('[data-mobile-click-b64]').each(function(element){
    element.addEventListener('click', function(e){
      e.preventDefault;
      e.stopPropagation;
      var telephone = atob(element.getAttribute('data-mobile-click-b64'));
      element.innerHTML = "<a href='tel:" + cleanPhone(telephone) + "'>" + telephone + "</a>";
    })
  })
  u('[data-click-to-call]').handle('click', event => {
    const phone = atob(u(event.target).data('click-to-call')) || false
    if(!phone){ throw new Error('Invalid phone number recieved', phone) }
    window.location.href = 'tel://' + cleanPhone(phone)
  })
}
