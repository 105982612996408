import { onViewportChange } from '~/common/js/Responsive'
import { subDays } from 'date-fns'
import { noticeCategoryDisplayName } from './constants'

/**
 * @fileOverview
 * @name recent-notices.js
 * @author Mark Beukers (Send all complaints to slack)
 * @related ~/work/finda/html/templates/apncore/includes/recent-notices.html
 *
 * This widget works by binding onto skeleton HTML and enhances it with the provided notices adding
 * interactivity. The general flow is: Setup -> Render -> BindEvents -> Render on events firing
 *
 * Once setup, most bound events will end up changing the internalState which will then lead to
 * a call to `draw` that will re-filter via `getNotices` and re-render the correct data.
 *
 * @TODO - Currently will act a little odd if you resize after initialize between mobile/non-mobile, if isMobile
 *  can be updated on windowResize that will solve any issues. Also remember to add the unbind :)
 *
 * type Notice = {
 *   id?: int,
 *   name: string,
 *   date: string,
 *   category: string,
 *   state: string }
 */

// The main entry function responsible for handling the data rendering/filtering of recent events.
// RecentNoticesWidget :: Notice[] -> undefined
const RecentNoticesWidget = (notices, loadMobileJS) => {
  const isMobile = () => window.innerWidth < 768
  const pageSize = 10 // Max records to show on each page on mobile

  const noNoticeinnerHTML = (category, categoryDisplayName) =>
    `
    <section class="recent-notices-no-data">
      <h3>View All ${categoryDisplayName}.</h3>
      <p>You've seen all the recent ${categoryDisplayName.toLowerCase()} for now. Explore all notices via the button below.</p>
      <a class='view-notice-btn' href='${window.rnn.site.tributes}all-locations/${category}' aria-label="View notices"></a>
    </section>
    `

  const $recent = document.querySelector(`div#recent-notices${loadMobileJS ? '-mb' : ''}`)
  if (!$recent) return

  const $body = $recent.querySelector(`.notices_item${loadMobileJS ? '_mb' : ''}`)
  if (notices.length === 0) {
    $body.innerHTML = noNoticeinnerHTML('death-notices', 'Death Notices')
  }

  // en-AU = "d/MM/yyyy" (eg: 01/02/2009)
  const formatDate = d => Intl.DateTimeFormat('en-AU', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d)
  const today = formatDate(new Date())
  const yesterday = formatDate(subDays(new Date(), 1))

  // DOM Binding
  const $tabs = [...$recent.querySelectorAll(`#recent-tabs${loadMobileJS ? '-mb' : ''} a`)]
  const $date = $recent.querySelector(`#recent-select${loadMobileJS ? '-mb' : ''} select[name=date]`)
  const $state = $recent.querySelector(`#recent-select${loadMobileJS ? '-mb' : ''} select[name=state]`)
  const $pagi = $recent.querySelector('#recent-pagi')
  const $pageL = $pagi.querySelector('.pagi-left')
  const $pageR = $pagi.querySelector('.pagi-right')
  const $dots = $pagi.querySelector('.pagi-dots')
  const $viewAll = $recent.querySelector(`#recent-footer${loadMobileJS ? '-mb' : ''} a`)

  // Construct the internal state
  const internalState = {
    page: isMobile() ? 1 : null, // null disables pagination for desktop
    totalPages: Math.ceil(notices.length / pageSize), // Total viewable pages currently
    date: $date.value, // Current date filter
    state: $state ? $state.value : 'all', // Current state or territory selected
    category: $recent.querySelector(`#recent-tabs${loadMobileJS ? '-mb' : ''} a.active`).hash.replace('#', '') // Tab Selected
  }

  // Helper for monkeypatching a function (f) to first cancel propagation and default behaviour
  // cancel :: Function(*) -> Function(Event) -> *
  const cancel = f => e => { e.preventDefault(); e.stopPropagation(); return f(e) }

  // Creates a new filtered set of results based on the internalState
  // getNoticies :: internalState -> Notice[]
  const getNotices = ({ page, date, state, category }) => {
    const filtered = notices
      .filter(n => (date === 'Recent')
        || (date === 'Today' && n.date === today)
        || (date === 'Yesterday' && n.date === yesterday))
      .filter(n => state === 'all' || state === n.state)
      .filter(n => category === 'all' || category == n.category)
      .sort((x, y) => x.name.toLowerCase() > y.name.toLowerCase() ? 1
        : x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 0)
    internalState.totalPages = Math.ceil(filtered.length / pageSize)
    return page === null
      ? filtered
      : filtered.slice(pageSize * (page - 1), pageSize * page)
  }

  // renderLine :: Notice -> String
  const renderNotice = ({ url, name, location }) =>
    `<a class='notice-card' href="${url}">
      <span class="name">${name}</span>
      ${loadMobileJS ? `<span class="location">${location}</span>` :
      `<div class="location_date">
          <span class="location">${location}</span>
        </div> `}
    </a>`

  //    renderDot :: Int -> String
  const renderDot = i => `<a href="#${i + 1}" class="${internalState.page === i + 1 ? 'active' : ''}">&bull;</a>`

  //    render :: () -> undefined
  // The main render function, is called whenever the state is mutated and a redraw is required.
  const render = () => {
    // Render the body with currently applicable notices
    const availableNoticesInnerHTML = getNotices(internalState).map(renderNotice).join('')
    $body.innerHTML = availableNoticesInnerHTML || noNoticeinnerHTML(internalState.category, noticeCategoryDisplayName[internalState.category])

    // Re-render and re-bind dots
    $dots.innerHTML = [...Array(internalState.totalPages).keys()].map(renderDot).join('')
    const $newDots = [...$dots.querySelectorAll('a')]
    $pagi.className = $newDots.length === 0 ? 'hidden' : ''
    $pageR.className = 'pagi-right' + (internalState.page < internalState.totalPages ? ' active' : '')
    $pageL.className = 'pagi-left' + (internalState.page > 1 ? ' active' : '')
    $newDots.map(x => x.onclick = cancel(function (e) {
      if (e.target.className === 'active') { return false }
      internalState.page = parseInt(e.target.hash.replace('#', ''))
      return render()
    }))
  }

  // Render the Date select box's <options>'s based on all currently available dates
  const uniqueDates = new Set(notices.map(notice => notice.date))
  $date.innerHTML = `
  <option value='Recent' selected='selected'>Recent</option>
  ${uniqueDates.has(today) ? "<option value='Today'>Today</option>" : ""}
  ${uniqueDates.has(yesterday) ? "<option value='Yesterday'>Yesterday</option>" : ""}
  `

  // Event Handling
  $date.onchange = function (e) {
    internalState.date = e.target.value;
    internalState.page = isMobile() ? 1 : null
    render()
  }

  if ($state) {
    $state.onchange = function (e) {
      internalState.state = e.target.value;
      internalState.page = isMobile() ? 1 : null
      render()
    }
  }

  // Change the selected tab
  $tabs.map(x => x.onclick = cancel(function (e) {
    internalState.page = isMobile() ? 1 : null
    internalState.category = e.target.hash.replace('#', '')

    const parts = $viewAll.href.split('/').slice(0, -2)
    $viewAll.href = parts.concat([internalState.category, '']).join('/') + '?sort=date'

    $tabs.map(x => x.className = x.className.replace('active', ''))
    // e.target.parentElement.className += ' active'
    e.target.className += ' active'

    render()
  }))

  // Mobile Pagination
  $pageR.onclick = cancel(function (e) {
    internalState.page = Math.min(internalState.page + 1, internalState.totalPages)
    render()
  })
  $pageL.onclick = cancel(function (e) {
    internalState.page = Math.max(internalState.page - 1, 1)
    render()
  })

  // Finish up
  render()

  // Responsive
  onViewportChange(() => {
    internalState.page = isMobile() ? 1 : null
    render()
  })

  // Cleanup function, when called this should remove all references to the instance from the
  // DOM and allow for GC
  return () => {
    $pageL.onclick = null
    $pageR.onclick = null
    $tabs.map(x => x.onclick = null)
    $state.onchange = null
    $date.onchange = null
  }
}

export default RecentNoticesWidget
