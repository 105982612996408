import rnn from '../../common/js/rnn'

import Galleria from 'galleria'

export default function ImageGalleries() {

  // The Theme
  let themeUrl = rnn.site.mediaURL + 'tributesv2/js/galleria.classic.js';

  //
  // Notices Gallery Loader
  //

  // Get the Gallery images
  let imageGallery = document.getElementById('galleria');

  // Get the modal
  let modal = document.getElementById("myModal");

  // Get the button that opens the modal
  let btn = document.getElementById("viewDktpGallery");

  //Get the button from mobile view
  let mbbtn = document.getElementById("viewGallery");

  if (imageGallery != null) {

    // Get the <span> element that closes the modal
    let span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    if (span != null) {
      span.addEventListener('click', () => {
        modal.style.display = "none";
      });
    }

    // When the user clicks anywhere outside of the modal, close it
    window.addEventListener('click', function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    });
  } else {
    let galleryContainer = document.getElementsByClassName('imgGalleryLinkContainer');
    if (galleryContainer.length >= 1) {
        galleryContainer[0].style.display = "none";
    }
  }

  const galleria = () => {

      // Load the classic theme
      Galleria.loadTheme(themeUrl);

      // Initialize Galleria
      Galleria.run('#galleria');
  }

  // Handle the gallery button click event here
  const eventHandler = ($button) => {
      $button.addEventListener('click', () => {
            modal.style.display = "block";
            galleria(); //Initialize galleria on click
      });
  }

  // When the user clicks the button, open the modal
  if (btn != null) {
      eventHandler(btn);
  }

  // When the user clicks the button, open the modal in mobile view
  if (mbbtn != null) {
      eventHandler(mbbtn);
  }

  //
  // Articles & Obituaries Gallery Loader
  //

  const obituariesLoader = () =>{
    // Load the classic theme
    Galleria.loadTheme(themeUrl);
    // Initialize Galleria
    Galleria.run('#obituaries-image-gallery');
  }

  let obituariesGallery = document.getElementById("obituaries-image-gallery");
  if (obituariesGallery != null) {
    //initialize here
    obituariesLoader();
  }

}
