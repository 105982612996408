import u from 'umbrellajs'
import { tryCatch } from 'rambda'

const parseJson = tryCatch(JSON.parse, false)

export default {
  init: NCAOmniture,
  bindTracking: bindTracking,
}

function NCAOmniture(){
  bindTracking()
}

export function bindTracking(){
  const $tracking = u('*[data-omniture]:not([data-omniture-tracked])')
  $tracking.each(function(node){
    const payload = getTrackingPayload(node)
    u(node).data('omniture-tracked', true)
    if(payload === false){
      return console.debug('Invalid Omniture tracking data: ', parseJson(u(node).data('omniture')), node)
    }

    if(payload.click){
      if(typeof payload.form === 'boolean' && payload.form){
        const $form = u(node).closest('form')
        console.debug('Bind Omniture form submit', $form)
        $form.on('submit', function(e){
          sendEvent(payload)
        })
      } else {
        console.debug('Bind Omniture click', node)
        u(node).on('click', function(e){
          sendEvent(payload)
        })
      }
    } else {
      // All non clicks events will be delayed to save cost
      console.debug('Delayed Omniture tracking event registered', payload)
      sendEvent({...payload, delay: true})
    }
  })
}

function enhanceValue(value){
  const pageData = window.pageData || {}
  let pageSections = window.location.pathname.slice(1,-1).split('/')
  if( pageSections[0] !== pageData.sector ){ pageSections = [pageData.sector].concat(pageSections) }

  // Find "site slug" based on standalone vs whitelabel
  const SITE = ['lcl', 'myt'].find(site => site === pageData.site) !== false
        ? pageData.sector === 'tributes' ? 'mytrbiutes' : 'buysearchsell'
        : pageData.sector

  return value
    .replace('$SITE', SITE)
    .replace('$LOCATION', pageSections[1])
    .replace('$CLASSIFICATION', pageSections[2])
}

// Takes an Umbrella element and safely attempts to parse the the omniture payload
function getTrackingPayload(node){
  let data = u(node).data('omniture')
  const json = parseJson(data)

  // Use a Pair to pass a value with an alias
  let value = ''
  if(Array.isArray(json) && json.length === 2){
    value = json[1]
    data = json[0]
  }

  // Alias tracking params
  // Where most events should be registered for documentation purposes
  const instantTrack = (eVars, events = []) => ({ eVars, events, click: true, delay: false })
  const submitTrack = (eVars, events = []) => ({ eVars, events, click: true, delay: false, form: true })
  const delayTrack = (eVars, events = []) => ({ eVars, events, click: true, delay: true })
  switch(data){
    // NewsConcierge
    // @TODO: @FIXME refers to an event we don't have a new evar for from NCA
    case 'place-an-ad': return instantTrack({eVar157: value }) // value: position (bottom or top)
    // CommisionFactory and Shortlyster
    // @FIXME: case 'commission-factory': return instantTrack({eVar13: 'commissionfactory'}, ["event216"]) // @NOTE: Not technically actually used...
    // @FIXME: case 'shortlyster': return instantTrack({eVar13: 'shortlyster'}, ["event216"])
    // An external link from a specific notice/ad
    // @FIXME: case 'external-ad': return instantTrack({eVar11: value}, ["event27"]) // value: ad.id
    // Email or Phone Contact events (Click to call)
    case 'phone': return instantTrack({eVar163: 'phone', eVar159: value}, ['event269']) // value: ad.id
    case 'email': return instantTrack({eVar163: 'email', eVar159: value}, ['event269']) // value: ad.id
    case 'email-submit': return submitTrack({eVar163: 'email-submit', eVar159: value}, ['event269']) // value: ad.id
    // Custom internal links, can use delay here
    case 'carousel': return delayTrack({eVar23: enhanceValue(`$SITE|${value}|$LOCATION|$CLASSIFICATION`)}, ['event10']) // value: index
    case 'card': return delayTrack({eVar158: value}) // value: card index
  }

  // Fallback to parse a full payload from JSON if thats possible
  // @TODO: Probably don't want or need to do this anymore but will leave here to give us extra room to adjust in the short term
  if(!json || Array.isArray(json)){ return false }
  let payload = {events: [], click: true, delay: true}

  // Legacy format, for now while transitioning
  if( json.eVar && json.value ){ payload.evars = {[json.eVar]: json.value} }
  if( json.clickStoreEvar ){ payload.delay = true }
  if( json.clickTrack === true){ payload.click = true }

  // New Foramt
  if( json.event ){ payload.events.push(json.event) }
  if( json.events ){ payload.events = [...payload.events, ...json.events] }
  if( json.eVars ){ payload.eVars = {...payload.eVars, ...json.eVars} }
  if( json.delay ){ payload.delay = true }
  if( json.click === false ){ payload.click = false }

  return payload
}

// Sends a custom event to Adobe analytics.
//
// events - An array of events, can be empty
// data - should contain eVar data (eg: {eVar25: "module click"})
// delay - will delay sending the event until next page loading
export function sendEvent({events = [], eVars = {}, delay = false}){
  window.mready = window.mready || [];
  // @TODO: Remove in the cleanup
  console.debug('NCA Omniture Push', {events: JSON.stringify(events), eVars: JSON.stringify(eVars), delay})
  window.mready.push(function(metrics) {
    // `ev` sends the event immediately, `npv` sends it in the "Next Page View"
    const trackFun = delay === true ? metrics.npv : metrics.ev
    trackFun({
      events,
      ...eVars,
    })
  })
  return true
}
