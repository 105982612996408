import { Loader } from '@googlemaps/js-api-loader'
import rnn from '../../common/js/rnn'


// Funeral Director Google Map Multiple Marker Implementation
export const FuneralDirectorMap = () => {
  const googleMapContainer = document.querySelector('.fd-right-column .map-container .map')
  const activeFuneralDirector = document.querySelector('.map-container .active-funeral-director')

  // Fetch all required information from the funeral directors' listing page
  const funeralDirectorsData = []
  const funeralDirectors = document.querySelectorAll('.fd-list-item, .std-list-item')

  funeralDirectors.forEach((funeralDirector) => {
    const title = funeralDirector.querySelector('h5').textContent.trim()
    const fdLogo = funeralDirector.querySelector('img').getAttribute('data-src')
    const fdLink = funeralDirector.querySelector('a').getAttribute('href')
    const { geoposition, address } = JSON.parse(funeralDirector.getAttribute('data-map-marker'))
    // Retrieve funeral director latitude and longitude from tuple formatted (lat, long)
    const [lat, lng] = geoposition.replace(/[()]/g, '').split(',').map(Number)

    if (title && address && lat && lng && fdLink && fdLogo) funeralDirectorsData.push({ title, address, lat, lng, fdLink, fdLogo })
  })

  const loader = new Loader({
    apiKey: window.rnn.site.googleMapKey,
    version: 'weekly',
  })

  loader.importLibrary('marker').then(() => {
    const map = new window.google.maps.Map(googleMapContainer, {
      zoom: 10,
      minZoom: 4,
      maxZoom: 17,
      zoomControl: window.innerWidth > 768,
      mapTypeControl: false,
      clickableIcons: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapId: 'FUNERAL_DIRECTOR_LISTING_PAGE_MAP',
    })

    let prevActiveMarker

    funeralDirectorsData.forEach((data) => {
      const customMarker = document.createElement('img')
      customMarker.src = rnn.site.mediaURL + 'tributesv2/images/google-map-pin.svg'

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        map: map,
        title: data.title,
        position: { lat: data.lat, lng: data.lng },
        content: customMarker
      })

      window.google.maps.event.addListener(marker, 'click', () => {
        map.panTo({ lat: data.lat, lng: data.lng })

        if (prevActiveMarker && prevActiveMarker !== marker) {
          // Update the active pin and reset the previous pin's icon when a marker is clicked
          prevActiveMarker.content.src = rnn.site.mediaURL + 'tributesv2/images/google-map-pin.svg'
        } else if (prevActiveMarker && prevActiveMarker === marker) {
          // Resets map to initial state when the active pin is clicked again
          prevActiveMarker = undefined
          map.fitBounds(mapBounds)
          activeFuneralDirector.style.display = 'none'
          marker.content.src = rnn.site.mediaURL + 'tributesv2/images/google-map-pin.svg'
          return
        }

        prevActiveMarker = marker
        marker.content.src = rnn.site.mediaURL + 'tributesv2/images/google-map-pin-clicked.svg'

        activeFuneralDirector.style.display = 'flex'
        activeFuneralDirector.innerHTML = `
        <img class='active-fd-logo' src='${data.fdLogo}' alt='${data.title}' />
          <div class='active-funeral-director-info'>
            <h6>${data.title}</h6>
            <span class='active-fd-location'>
              <i class='active-fd-location-icon'></i>
              <span class='active-fd-location-text'>${data.address}</span>
            </span>
            <a class='active-fd-view-profile' href='${data.fdLink}' target='_blank' rel='noopener noreferrer'>View Profile <i class='right-arrow-icon'></i></a>
          </div>
        `
      })
    })

    // Adjust the center of the map to fit all markers
    const mapBounds = new window.google.maps.LatLngBounds()
    funeralDirectorsData.forEach((data) => {
      mapBounds.extend(new window.google.maps.LatLng(data.lat, data.lng))
    })

    map.fitBounds(mapBounds)
  })
}
