import $ from 'jquery'
import { onViewportChange } from "../../common/js/Responsive"


const InitializeSlickCarousel = () => {
  $('.notices-carousel').not('.card-initialized').slick({
    slidesToShow: 2,
    variableWidth: true,
  });
}

// To make sure it doesn't get loaded in desktop
onViewportChange(viewport => {
  if (viewport === 'tablet' || viewport === 'mobile') {
    InitializeSlickCarousel()
  } else {
    $('.notices-carousel.card-initialized').slick('unslick')
  }
})
