// ********************************************************
//   Centralised Constant repository for BSS & MyTributes
// ********************************************************

// Body classes to add for when various UI elements are toggled
export const SHOW_REFINEMENT_CLASS = 'refinement-showing';

// Mobile search
export const searchInputEl = document.getElementById("bss-search-keywords");
export const formExpandEl = document.querySelector(".input-toggle-section");
export const mobileSearchTitleEl = document.querySelector(".mobile-search-title");
export const mobileSearchInputEl = document.querySelector(".input-always-show");
export const closeMobileFormEl = document.querySelector(".close-mobile-form-icon");
export const searchFormEl = document.querySelector('[data-search-form]');
export const searchIconEl = document.querySelector(".bss-icon-search");
export const documentBodyEl = document.body;

// Refinement filters
export const refineEl = document.querySelector('[data-interaction-refine-toggle]');
export const drawerEl = document.querySelector('[data-interaction-refine-drawer]');
export const refineCloseEl = document.querySelector('[data-interaction-refine-close]');

// // params we may want to send through to newsconcierge
export const AFFLIATE_DEFAULTS = window.referralLinks || ['sourceCode', 'gclid'];
