import $ from 'jquery';

featureContent();

$(window).width() <= 767 && paginationMobileView()
$(window).on('resize', () => {
  paginationMobileView()
  navbarFilterScroll()
})

$('.clearFilter').on('click', () => {
  $('.navbar-search-form').trigger("reset");
  $(".navbar-search-form").find('input').val('');
  $('select').prop('selectedIndex', 0);
})

$('.clearRefineFilter').on('click', () => {
  $('.refine-search-filter-form').trigger("reset");
  $('.refine-search-filter-form').find('input').val('');
  $('select').prop('selectedIndex', 0);
})

$('#filterList').on('click', () => {
  $('#showFilterList').toggle();
})

$('.backto-Top').on('click', () => {
  $("html, body").animate({
    scrollTop: 0
  }, 1000);
})

function navbarFilterScroll() {
  let lastScroll = 0;
  $(window).scroll(function () {
    const navbarscroll = {
      breakpoint: 285,
      currentScroll: window.scrollY
    }
    if ($(this).scrollTop() > navbarscroll.breakpoint) {
      navbarScrollHandler(navbarscroll.currentScroll, lastScroll);
    } else {
      $("#funeral-desktop-ad, #fd-desktop-ad").css({ "position": "relative", "top": '0px' });
      $("#navbar-search-filter").css({ "position": "relative" }).addClass('is-visible');
    }
    lastScroll = navbarscroll.currentScroll;
  });
}

function navbarScrollHandler(currentScroll, lastScroll) {
  let $navbarEl = $("#navbar-search-filter");
  let $fdDesktopEl = $("#funeral-desktop-ad, #fd-desktop-ad");
  if (currentScroll < lastScroll) {
    $navbarEl.css({ "position": "sticky", "top": 0, "z-index": 101 }).delay(500).addClass('is-visible');
    showFilterCurrentScroll($fdDesktopEl);
  } else {
    isNavBarFilterCondition(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
  }
}
function showFilterCurrentScroll($fdDesktopEl) {
  if ($('#showFilterList').css('display') === 'block') {
    $fdDesktopEl.css({ "position": "sticky", "top": '250px' });
  } else {
    $fdDesktopEl.css({ "position": "sticky", "top": '130px' });
  }
}
function isNavBarFilterCondition(currentScroll, lastScroll, $navbarEl, $fdDesktopEl) {
  if (isNavbarSticky(currentScroll, lastScroll)) {
    $navbarEl.css({ "position": "sticky", "top": 0, "z-index": 101 }).delay(500).addClass('is-visible');
    $fdDesktopEl.css({ "position": "sticky", "top": '130px' });
  } else {
    showFilterList(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
  }
}
function showFilterList(currentScroll, lastScroll, $navbarEl, $fdDesktopEl) {
  if ($('#showFilterList').css('display') === 'block') {
    isShowFilterOpen(currentScroll, lastScroll, $navbarEl, $fdDesktopEl);
  } else {
    $navbarEl.removeClass('is-visible').css({ "position": "relative" });
    $fdDesktopEl.css({ "position": "sticky", "top": '30px' });
  }
}
function isShowFilterOpen(currentScroll, lastScroll, $navbarEl, $fdDesktopEl) {
  if (currentScroll - $('#showFilterList').height() == lastScroll) {
    $navbarEl.css({ "position": "sticky", "top": 0, "z-index": 101 }).delay(500).addClass('is-visible');
    $fdDesktopEl.css({ "position": "sticky", "top": '250px' });
  } else {
    $navbarEl.removeClass('is-visible').css({ "position": "relative" });
    $fdDesktopEl.css({ "position": "sticky", "top": '30px' });
  }
}
function isNavbarSticky(currentScroll, lastScroll) {
  return !!(currentScroll < lastScroll && $('#showFilterList').css('display') === 'none');
}

// pagination for mobile view
function paginationMobileView() {
  if ($(window).width() <= 767) {
    let pagination_li_active = '.page-navigation li.active';
    $('.page-navigation li').css('display', 'none');
    $('.page_previous').css('display', 'block');
    $('.page_next').css('display', 'block');
    $('.pagination-gap').css('display', 'block');
    paginationNavActive(pagination_li_active);
  } else {
    $('.page-navigation li').css('display', 'block');
  }
}

function paginationNavActive(pagination_li_active) {
  if ($(pagination_li_active).hasClass('page_previous')) {
    $(window).width() <= 420 ? $(pagination_li_active).nextAll().slice(0, 2).css('display', 'block') : $(pagination_li_active).nextAll().slice(0, 3).css('display', 'block');
  } else if ($('.page-navigation li').hasClass('disabled') && !($('.page-navigation li').hasClass('page_next'))) {
    $(pagination_li_active).css('display', 'block');
    $(pagination_li_active).next("li").css('display', 'block');
    $(window).width() <= 420 ? $(pagination_li_active).prevAll().slice(0, 1).css('display', 'block') : $(pagination_li_active).prevAll().slice(0, 2).css('display', 'block');
  } else if (!($(pagination_li_active).hasClass('page_next')) || !($(pagination_li_active).hasClass('page_prevous'))) {
    paginationNavigation(pagination_li_active);
    $(pagination_li_active).css('display', 'block');
    $(pagination_li_active).prev("li").css('display', 'block');
    $(window).width() <= 420 ? $(pagination_li_active).nextAll("li").slice(0, 0).css('display', 'block') : $(pagination_li_active).nextAll("li").slice(0, 1).css('display', 'block');
  }
}
function paginationNavigation(pagination_li_active) {
  if ((parseInt($(pagination_li_active).data('pagenumber')) == 3) || parseInt($(pagination_li_active).data('pagenumber')) == 4) {
    $(".page-navigation li:nth-child(2)").remove();
    $(".page-navigation li:nth-child(1)").after($("<li class='pagination-gap'>").text("..."));
  } else if ((parseInt($(pagination_li_active).data('endpagenumber')) == 3) || parseInt($(pagination_li_active).data('endpagenumber')) == 4) {
    $(".page-navigation li:nth-last-child(2)").remove();
    $(".page-navigation li:nth-last-child(1)").before($("<li class='pagination-gap'>").text("..."));
  } else if ((parseInt($(pagination_li_active).data('pagenumber')) == 1)) {
    $(pagination_li_active).css('display', 'block');
    $(pagination_li_active).prev("li").css('display', 'block');
    $(window).width() <= 420 ? $(pagination_li_active).nextAll("li").slice(0, 1).css('display', 'block') : $(pagination_li_active).nextAll("li").slice(0, 2).css('display', 'block');
  }
}

function featureContent() {
  if (document.getElementsByClassName("fd-list-item")[0]) {
    $('.fd-feature-content').css('display', 'block');
  } else {
    $('.fd-feature-content').css('display', 'none');
  }
}
