import {last} from 'rambda'
import $ from 'jquery'

// Define selectors for ad stripping (Removing)
const DESKTOP_ADS = '.ad-block.ad-mrec, .ad-block.ad-leader, .ad-block.ad-halfpage, .desktop-ad'
const MOBILE_ADS = '.ad-block.ad-m-banner, .ad-block.ad-m-mrec, .mobile-ad'

/**
 * Ads
 *
 * Handles all specific advertisement based hacks and solutions for BSS and MYT including:
 * - [data-size] - Provides the smallest acceptable size that the ad fits into
 * - [data-real-size] - Provides the actual size of the ad, regardless of what it should be
 * - DOM removal of responsive ads that don't apply to the user's responsive viewport
 * - Bonzai Ads: .bz-page-wrapper support
 * - TODO: Maybe also some kind of custom event for when all ads are fully loaded?
 * */
export default function Ads() {
  const $body = document.querySelector("body");
  const $container = document.querySelector('body .bss, body .tributes, .buysearchsell body, .mytributes body')
  const $adhesive = $($('.ad-adhesive, .adGroupBottom, #mobileAd0')[0])

  // Only show ad-adhesive banner ad on first scroll
  const mobileScrollHandler = function() {
    let panel = document.querySelector('.ad-adhesive, #fixed-ad');
    if (panel !== null) panel.classList.add('ad-adhesive-show');
    // Remove the event listener after the first scroll
    window.removeEventListener('scroll', mobileScrollHandler, false);
  };
  window.addEventListener('scroll', mobileScrollHandler, false);

  // Strip out ads that don't belong in the current View
  const $ads = removeAds([...document.querySelectorAll(".ad-block")])

  // Then we will just observe the body for .adcore to appear
  const adsReadyObserver = new MutationObserver((mutations) => {

    // Once present, we know ads are actually going to load
    const adCorePresent = mutations[0].target.classList.contains("adcore");
    if (!adCorePresent) { return; }

    // Handle resize from mobile ad banner changing
    const getAdhesiveHeight = function(){
      // @HACK: Using jquery for its is :hidden functionaliy
      return $adhesive.length && !$adhesive.is(':hidden') ? $adhesive.outerHeight() : 0
    }
    if(typeof ResizeObserver === 'function' && $adhesive.length > 0){
      const adhesiveObserver = new ResizeObserver(function(){
        $('.fixed-bottom').css('bottom', getAdhesiveHeight())
      })
      adhesiveObserver.observe($adhesive[0])
    }

    // Hook Mutation events onto all ad-blocks to listen for their specific ready states
    $ads.map(watchAd)
    adsReadyObserver.disconnect();
  });
  adsReadyObserver.observe($body, { attributeFilter: ["class"] });

  // Bonzai Observer - Check for Bonzai ads being injected into the DOM. If they
  // do get inserted, add the bz-wrapper.
  const bonzaiObserver = new MutationObserver((mutations) => {
    // Filter through nodesAdded and look for .bz-custom-container being added to the DOM
    const isBonzaiAdded = mutation => [...mutation.addedNodes]
          .filter(n => n.classList?.contains('bz-custom-container')).length > 0
    const bonzaiPresent = mutations.filter(isBonzaiAdded).length > 0
    if(!bonzaiPresent){ return; }
    // We found a bonzai injection, enable bonzai mode
    $container.classList.add('bz-page-wrapper')
    bonzaiObserver.disconnect();
  })
  bonzaiObserver.observe($body, { childList: true, attributes: false })
}



//           removeAds :: DOMElement[] => DOMElement[]
export const removeAds = (ads) => {
  // @NOTE: At this time, this is the intended logic for responsive ads as NCA
  // GAM doesn't appear to have a proper for responsive ads so we must just make
  // our best guess based on our responsive design size
  const isMobile = document.body.clientWidth <= 736 ? true : false
  return ads.filter(ad => {
    const shouldBeRemoved = ad.matches(isMobile ? DESKTOP_ADS : MOBILE_ADS)
    if( shouldBeRemoved ){ ad.remove() }
    return !shouldBeRemoved
  })
}

//           watchAd :: DOMElement -> DOMElement
export const watchAd = (ad) => {

  // Our observer is using a setTimeout(.., 1) to allow us to arrive right after
  // the page as been redrawn to accomidate the ad's potentional new size
  const observer = new MutationObserver(() => setTimeout(() => {
    // Get the width/height ideally from the inner element to get a more accurate
    // number without our own padding/margins being applied.
    const { clientHeight: height, clientWidth: width } = ad.firstChild || ad;
    const realSize = `${width}x${height}`
    const bestFit = getAcceptableSize(ad.getAttribute('data-ad-size') || "")(realSize)

    // Apply the best fit and the real size attributes
    ad.setAttribute('data-real-size', realSize);
    ad.setAttribute('data-size', bestFit)
    observer.disconnect();

    // 3rd party lazy loaded ads.
    //
    // Some ads are liars, google will tell us it's a halfpage slot with an MREC
    // sizing (300x250) but then the 3rd party code will then notice it's a halfpage
    // slot and apply styles to make itself takeup that space.
    //
    // We solve this by re-listening for any changes to the style attribute on the
    // main iframe and making sure to re-inspect the element if it tries to resize.
    const $iframe = ad.querySelector('iframe')
    if(!$iframe){ return }
    observer.observe($iframe, { attributeFilter: ["style"] })
  }), 1);

  // By observing data-google-query-id, we know that once it is set that google has finished
  // initialising the ad slot
  observer.observe(ad, { attributeFilter: ["data-google-query-id"] })
  return ad
};

const parseSize = s => s.split('x').map(i => parseInt(i, 10))

//           getAcceptableSize :: String -> String -> String
export const getAcceptableSize = (sizes) => {
  // Sorted by total size
  //    acceptedSizes :: String -> Array of Pair<Int, Int>
  const acceptedSizes = (sizes || '')
        .split(',')
        .map(parseSize)
        .sort((a,b) => (a[0]*a[1]) - (b[0]*b[1]) )

  // String -> Pair<Int, Int>
  return (adSize) => {
    const size = parseSize(adSize)
    for(const accept of acceptedSizes){
      if( accept[0] >= size[0] && accept[1] >= size[1] ){
        // Fits within the acceptable size
        return accept.join('x')
      }
    }

    // Nothing fit, return the largest acceptable size
    return last(acceptedSizes).join('x')
  }
}
