import $ from 'jquery'

export default function Dialog(dialogEl, overlayEl) {
  this.dialogEl = dialogEl;
  this.overlayEl = overlayEl;
  this.focusedElBeforeOpen = null;
  var focusableEls = this.dialogEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
  this.focusableEls = Array.prototype.slice.call(focusableEls);
  this.firstFocusableEl = this.focusableEls[0];
  this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];
  this.close(); // Reset
  this.open(); // Open the dialog
}

Dialog.prototype.open = function () {
  var Dialog = this;
  this.dialogEl.setAttribute('aria-hidden', false);
  this.overlayEl.setAttribute('aria-hidden', false);
  this.dialogEl.addEventListener('keydown', function (e) {
    Dialog._handleKeyDown(e);
  });
  this.overlayEl.addEventListener('click', function (e) {
    if (e.target.className !== 'dialog-overlay') { return }
    e.stopPropagation();
    Dialog.close();
  });
  this.firstFocusableEl.focus();
};

Dialog.prototype.close = function () {
  this.dialogEl.setAttribute('aria-hidden', true);
  this.overlayEl.setAttribute('aria-hidden', true);
  if (this.focusedElBeforeOpen) {
    this.focusedElBeforeOpen.focus();
  }
};

Dialog.prototype._handleKeyDown = function (e) {
  var Dialog = this;
  function handleBackwardTab() {
    if (document.activeElement === Dialog.firstFocusableEl) {
      e.preventDefault();
      Dialog.lastFocusableEl.focus();
    }
  }

  function handleForwardTab() {
    if (document.activeElement === Dialog.lastFocusableEl) {
      e.preventDefault();
      Dialog.firstFocusableEl.focus();
    }
  }

  switch (e.key) {
    case "Tab":
      if (Dialog.focusableEls.length === 1) {
        e.preventDefault();
        break;
      }

      if (e.shiftKey) {
        handleBackwardTab();
      } else {
        handleForwardTab();
      }

      break;
    case "Escape":
      Dialog.close();
      break;
    default:
      break;
  }
};

Dialog.prototype.addEventListeners = function (closeDialogSel) {
  var Dialog = this;

  var closeDialogEls = document.querySelectorAll(closeDialogSel);
  for (var i = 0; i < closeDialogEls.length; i++) {
    closeDialogEls[i].addEventListener('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      Dialog.close();
    });
  }
};

$(document).ready(function () {

  const navDialogEl = document.getElementById('redesign-modal'); // MYT Redesign modal
  const siteDialogEl = document.getElementById('redirect-modal'); // Redirect from site modal
  var returnVisit = "";

  // Generic Modal
  $('.open-modal').on('click', function () {
    const modalEl = $('#' + $(this).data('open'))[0]
    const dialog = new Dialog(modalEl, $(modalEl).parent()[0])
    dialog.open()
  })

  // If no modals are found, abort
  if (!navDialogEl && !siteDialogEl) return

  // If the url contains a redirected parameter, open the redirect modal
  const urlParams = new URLSearchParams(window.location.search);
  const redirectSite = urlParams.get('rs');

  if (redirectSite) {
    const redirectDialogOverlay = document.getElementById('redirect-dialog-overlay');
    const rdKey = redirectDialogOverlay.attributes['modal-key'].value || 'returnVisit';
    returnVisit = localStorage.getItem(rdKey);
    const arrivedFromExternalSite = document.referrer !== '' && !document.referrer.includes(window.location.hostname)
    if (!returnVisit || arrivedFromExternalSite) {
      localStorage.setItem(rdKey, 'yes');
      const myDialog = new Dialog(siteDialogEl, redirectDialogOverlay);
      myDialog.addEventListeners('.close-dialog');
    }
  } else {
    // MYT Redesign modal (only show if there's no redirect modal)
    if (navDialogEl) {
      const dialogOverlays = document.querySelectorAll('[modal-key="sortOrderChange"]');
      const dialogOverlay = dialogOverlays[0];
      const lsKey = dialogOverlay.attributes['modal-key'].value || 'returnVisit';
      returnVisit = localStorage.getItem(lsKey);
      if (!returnVisit) {
        localStorage.setItem(lsKey, 'yes');
        setTimeout(e => {
          const myDialog = new Dialog(navDialogEl, dialogOverlay);
          myDialog.addEventListeners('.close-dialog');
        }, 3000);
      }
    }
  }
})
