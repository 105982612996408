import m from 'mithril'
import u from 'umbrellajs'
import axios from 'axios'
import { debounce } from 'rambdax'
import Dropdown from '../Dropdown'

// Takes the input value of the user and returns structured results from the backend
export const getRecords = async function (query, intl = false) {
  const result = await axios.get(`/location/autocomplete/simple/`, {
    params: {
      types: 'po+co',
      q: query,
      limit: 150,
      timestamp: +new Date,
      intl: intl ? 'True' : null,
    }
  })
  if (!result.data) return [];

  return result.data.split("\n")
    .map(line => line.split('|'))
    .map(tuple => ({ id: tuple[1], value: tuple[0] }))
}

// Extension of <Dropdown> that impliments a location selector based on the autocomplete
// api with support for toggling international on or off.
export function LocationComponent(initialState) {
  const state = {
    intl: false,
    records: [],
    value: '',
    ...initialState,
  }

  // We will sync for results after an input change or a toggling of the input
  const sync = debounce(async (e) => {
    if (e.target.name === state.label) { state.value = e.target.value }
    state.records = await getRecords(state.value, state.intl)
    state.loading = false
    m.redraw()
  }, 300)
  const onChange = sync
  const onToggleIntl = function (e) {
    state.intl = !state.intl
    sync(e)
  }

  // On selection, set the ID and clear records
  const onSelection = function (e, id) {
    state.id = id
    state.records = []
    m.redraw()
  }

  const after = (
    <li class="no-select">
      <label>
        <input type="checkbox" checked={state.intl} onchange={onToggleIntl} />
        Include results from outside Australia
      </label>
    </li>)

  return {
    view: () => (
      <Dropdown
        after={after}
        onChange={onChange}
        onSelection={onSelection}
        id={state.id}
        label={state.label}
        records={state.records}
        value={state.value}
        loading={state.loading}
      />
    )
  }
}

// Main Function to bind our components to the DOM
function LocationSelector() {
  u('form .location-selector[data-label]').each(element => {
    const initialState = {
      label: u(element).data('label'),
      id: u(element).data('default-id'),
      value: u(element).data('default-value'),
    }
    m.mount(element, LocationComponent(initialState))
  })
}

export default LocationSelector
