import $ from 'jquery'


var loginURL = '';
var appID = '';

////////////////////////////////////////////////////
/// What's goin on here APN?
////////////////////////////////////////////////////

// Facebook's method for capturing the login is here below by watching the event generated "FB.Event.subscribe('auth.authResponseChange'....."

// The way we do it instead is use the surrounding login form element's action that we put the facebook button inside of... in order to redirect them once they're logged in via facebook. So instead of watching events we use the jquery .on below to handle the login event with the login(next) function below.
//It's probably crude to assume that we're always putting this button into a form element, but that's where we store the login action url already so it was probably handy since we don't place FB login buttons anywhere else.

//See "$('.fb-login-button').on('click', function (event) {..."

// Initialise Facebook
function initFacebook(site) {

  var siteDomain = site.domain,
    channelURL = window.location.protocol + '//' + siteDomain + '/fb_channel.html',
    apiURL = window.location.protocol + "//connect.facebook.net/en_GB/sdk.js";

  appID = site.facebook.appID;
  loginURL = site.facebook.loginURL;

  // if theres no facebook appID hide facebook content otherwise do the facebook stuff
  if (appID.length === 0) {
    $('.only-facebook-api').hide();
  } else {

    // Define the Facebook asynchronous initialisation function
    window.fbAsyncInit = function () {
      FB.init({
        appId: appID,
        status: true,
        cookie: true,
        xfbml: true,
        oauth: true,
        channelUrl: channelURL,
        version: 'v4.0' // @TODO Update by Nov 2, 2021
      });

      // Replace the html class so that Facebook UI elements appear
      $('html').addClass('facebook-api').removeClass('no-facebook-api');

      // @NOTE: Removed as I don't think we use this in BSS/MYT
      // Enable activity API calls for likes
      // FB.Event.subscribe('edge.create', function (response) {
      //   var activity = rnn.modules.activity;
      //   activity && activity.record(activity.keyFromURL('liked', response));
      // });

    };

    // Add the Facebook root element
    $('body').append('<div id="fb-root"></div>');

    //Load the Facebook API async
    (function (d) {
      var js, id = 'facebook-jssdk', ref = d.getElementsByTagName('script')[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement('script'); js.id = id; js.async = true;
      js.src = apiURL;
      ref.parentNode.insertBefore(js, ref);
    }(document));

    // Activate Facebook login buttons:
    //Instead of using the <fb:login-button show-faces="false" width="200" max-rows="1"></fb:login-button> code suggested (relying on events to know if we logged in as suggested by FB) - we made our own login button.
    //<a href="#" class="fb-login-button fb_button fb-login-button" data-show-faces="false" data-max-rows="1" data-size="large">Login with Facebook</a>
    $('.facebook-login, .fb-login-button, .fb-login-button-new, .btn-facebook-mobile').on('click', function (event) {
      var $btn;
      event.preventDefault();
      $btn = $(this);
      loginAndRedirect($btn);
    });
  }
}

function loginAndRedirect($btn) {
  var redirectURL, $form, $nextField;
  redirectURL = '/';
  $form = $btn.closest('form');
  if ($form.length) {
    $nextField = $form.find('#id_next');
    if ($nextField.length) {
      redirectURL = $nextField.val();
    }
  }
  login(redirectURL);
}

// Facebook Login - called by our jQuery click even to have a redirect url captured.
function login(next) {
  next = next ? next : '/';
  var redirectURL = loginURL + '?next=' + next;

  FB.getLoginStatus(function (response) {
    if (response.authResponse) {
      //Should we maybe check response.status === 'connected' ??
      window.location = redirectURL;
    } else {
      FB.login(function (response) {
        if (response.authResponse) {
          window.location = redirectURL;
        }
      }, { scope: 'email' });
    }
    return false;
  });
  return false;
}

// Here we run a very simple test of the Graph API after login is successful.
// This testAPI() function is only called in those cases.
function testAPI() {
  FB.api('/me', function (response) { });
}

// Call initFacebook when using the Standard Facebook login
function fbLogin() {
  loginAndRedirect($('.fb-login-button'));
}

export default {
  init: initFacebook,
  fbLogin,
}
