import m from 'mithril'
import u from 'umbrellajs'
import Dropdown from '~/common/js/Dropdown'

// @HACK: Using the inputValue like this is such a hack to get around we don't have propper state storage for MYT
// This will not work with more than one of these in use
let inputValue = ''
export function PublicationComponent({id, value, data}){
  let currentData = data
  inputValue = value
  const onSelection = function(e, id, value){
    inputValue = value
    currentData = data
    document.activeElement.blur()
    m.redraw()
  }
  const onChange = function(e){
    inputValue = e.target.value
    currentData = data.filter(s => s.value.includes(inputValue))
    m.redraw()
  }
  return {
    view: () => (
      <>
        <label>Publication</label>
        <Dropdown
          value={inputValue}
          id={id}
          records={currentData}
          loading={false}
          label="publication"
          defaultValue="All Publications"
          onSelection={onSelection}
          onChange={onChange}
          minInput={0}
        />
        <span class="bss-icon-chevron-down drop-down-arrow"></span>
      </>
    )
  }
}

export default function PublicationSelector(){
  const $nodes = u('[data-refinement-select-publication]')
  if($nodes.length > 1){ console.error('There should not be more than 1!') }
  $nodes.map(function(node){
    // @HACK: Excluding publication from the form, will be simpler when using the
    // proper RefinementFilters.jsx
    u(node).closest('form').on('submit', function(){
      u(this).find('input[name=publication]').first().name = ''
      u(this).find('input[name=publication_id]').first().name = 'publication'
    }).on('reset', function(){
      u(this).find('input[name=publication]').first().value = 'All Publications'
      u(this).find('input[name=publication_id]').first().value = 'all-publications'
      inputValue = 'All Publications'
      m.redraw()
    })
    // Get the select data and mount the component
    const data = [...node.children].map(n => ({ id: n.value, value: n.innerText }))
    const selected = u(node).find('option[selected]').first()
    m.mount(node.parentElement, PublicationComponent({
      id: selected?.value,
      value: selected?.text,
      data,
    }))

  })
}
