import $ from 'jquery'
import Galleria from 'galleria'

// @HACK: expose Galleriea for plugins
window.Galleria = Galleria

// @TODO: Remove bower usage
// @TODO: Use siteConfig instead of a window reference

function buildInteractions(gallery, data){
  // we need to create an extra element in the galleria and insert existing elements into it.

  var $counterEl = $(gallery).find('.galleria-counter');

  $('<div class="galleria-details"></div>').insertBefore($counterEl);

  var $detailsEl = $(gallery).find('.galleria-details');
  $($counterEl).appendTo($detailsEl);

  var $infoEl = $(gallery).find('.galleria-info');
  $($infoEl).appendTo($detailsEl);

  // interaction to grow/shrink details of the photo.
  // we only want to engage this if there is info to show.
  if (data[0].description.length || data[0].title.length) {
    var baseHeight,
        expandedHeight;

    if (rnn.site.isDesktop === true) {
      baseHeight = '80px';
      expandedHeight = '150px';

      // Display comments by default for desktop only
      $('<i class="galleria-icon">&#xe800;</i>').appendTo($counterEl);
      $detailsEl.toggleClass('isExpanded');
    } else {
      baseHeight = '40px';
      expandedHeight = '100%';

      $('<i class="galleria-icon">&#xe801;</i>').appendTo($counterEl);
    }

    $counterEl.addClass('expandable');

    $counterEl.click(function() {
      if ($detailsEl.hasClass('isExpanded')) {
        $detailsEl.stop().animate({
          height: baseHeight
        }, 200, function(){
          $(gallery).find('.galleria-counter i').html('&#xe801;');
        });
      } else {
        $detailsEl.stop().animate({
          height: expandedHeight
        }, 200, function(){
          $(gallery).find('.galleria-counter i').html('&#xe800;');
        });
      }

      $detailsEl.toggleClass('isExpanded');
      return false;
    });
  }

  // full screen mode.
  var $stageEl = $(gallery).find('.galleria-stage');
  $('<div class="galleria-fullScrBtn"><i class="galleria-icon">&#xe817;</i></div>').appendTo($stageEl);

  $(gallery).find('.galleria-fullScrBtn').click(function() {
    $(gallery).data('galleria').toggleFullscreen();
  });

  // needs to move to the end of the container due to mobile device overlay issues.
  $detailsEl.insertAfter($(gallery).find('.galleria-fullScrBtn'));

  // set up new arrow icons.
  $(gallery).find('.galleria-image-nav-right').html('<i class="galleria-icon">&#xe816;</i>');
  $(gallery).find('.galleria-image-nav-left').html('<i class="galleria-icon">&#xe815;</i>');

  $(gallery).find('.galleria-thumb-nav-right').html('<i class="galleria-icon">&#xe812;</i>');
  $(gallery).find('.galleria-thumb-nav-left').html('<i class="galleria-icon">&#xe811;</i>');
}

// @TODO: Do we need/want to use width?
function atAGlanceGallery(gallery, data, height, width){
  // Load theme
  Galleria.loadTheme(window.rnn.site.mediaURL + 'rnn/base/js/galleria-templates/ataglance/galleria.ataglance.js');
  Galleria.configure({
    height: height,
    responsive: true,
    dataSource: data,
    debug: false,
    onLastShow: '.controls.next',
    onFirstShow: '.controls.prev',
    imagePosition: 'top',
    extend: function () {
      this.bind('image', function () {
        $('.controls.showTease:not(.active)').removeClass('showTease');
      });
    }
  });

  Galleria.run(gallery);
}

function buildGallery(gallery, data, height, width, hideCarousel){
  // Load theme
  Galleria.loadTheme('https://cdnjs.cloudflare.com/ajax/libs/galleria/1.6.0/themes/classic/galleria.classic.min.js');

  // set these for desktop only.
  if (window.rnn.site.isDesktop === true) {
    $(gallery).css('width', width);
    $(gallery).css('height', height);
    height = $(gallery).css('height', height);
  } else {
    height = 1;
  }

  var carousel = 'lazy';
  if (hideCarousel === true) {
    carousel = false;
  }

  Galleria.configure({
    height: height,
    thumbnails: carousel,
    responsive: true,
    imagePosition: 'top',
    extend: function(){
      data = this._data;
      gallery = this._target;

      setTimeout(function(){
        if($(gallery).find('.galleria-images img').length === 0){
          $(gallery).find('.galleria-stage .galleria-images').html('<p>Gallery images not available at this time.</p>');
          $(gallery).find('.galleria-loader').hide();
          $(gallery).find('.galleria-counter').hide();
          $(gallery).find('.galleria-image-nav').hide();
        }
      }, 12000);

      // build custom interactions
      buildInteractions(gallery, data);
    }
  });

  Galleria.run(gallery, {
    dataSource: data,
    transition: 'fade',
    debug: false,
    imageCrop: false,
    thumbCrop: true,
    fullscreenTransition: 'slide',
    imageTimeout: 12000
  });

  Galleria.ready(function() {
    this.lazyLoadChunks(9,0);
  });
}

function initAtAGlanceGallery() {
  var $galleries = $('.newsEntryGlance *[data-gallery]');

  if ($galleries.length) {
    $galleries.each(function() {
      var $this = $(this),
          height = $this.data('gallery').height,
          width = $this.data('gallery').width,
          data = $this.data('gallery').dataSource;

      if (window.rnn.site.isMobile === true) {
        height = 0.75;
      }

      $.ajax({
        url: data,
        dataType: 'json',
        crossDomain: false,
        success: function(data){
          atAGlanceGallery($this[0], data, height, width);
        }
      });
    });
  }
}

function initGalleries(){
  var $galleries = $('*[data-gallery]');

  if ($galleries.length === 1) {
    // we dont want history for bss
    if (!$galleries.data('gallery').disableHistory) {
      var historyPlugin = document.createElement('script');
      historyPlugin.src = window.rnn.site.mediaURL + 'plugins/galleria/plugins/history/galleria.history.min.js';
      document.getElementsByTagName('head')[0].appendChild(historyPlugin);
    }
  }

  if ($galleries.length) {
    $galleries.each(function() {
      var $this = $(this),
          galleryData = $this.data('gallery'),
          width = galleryData.width,
          hideCarousel = $this.data('hidecarousel'),
          dataUrl = $this.data('gallery').dataSource;

      var height = galleryData.height;

      if (galleryData.heightMobile && window.innerWidth < 767){
        height = galleryData.heightMobile;
      }

      if (galleryData.type === 'inlineEmbed') {
        var $article = $this.closest('.newsEntry'),
            articleData = $article.data('gallery-config');

        if (typeof articleData !== 'undefined') {
          height = articleData.height;
          width = articleData.width;
        }
      }

      //Use 1880px wide image for larger gallery
      if (width == 940)
        dataUrl += '?image_width=1880';

      if ($this.data('json-gallery') && $this.data('json-gallery').length > 0) {
        buildGallery(this, $this.data('json-gallery'), height, width, hideCarousel);
      }else{
        $.ajax({
          url: dataUrl,
          dataType: 'json',
          crossDomain: false,
          success: function(data){
            buildGallery($this[0], data, height, width, hideCarousel);
          }
        });
      }
    });
  }
}

export default {
  init: initGalleries,
  initAtAGlanceGallery: initAtAGlanceGallery,
}
