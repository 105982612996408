export const isMobile = () => window.matchMedia('(max-width: 767px)').matches
export const isTablet = () => window.matchMedia('(max-width: 1279px)').matches
export const isDesktop = () => window.matchMedia('(min-width: 1280px)').matches

// @TODO: Should probably have mobile-sm and desktop-lg
export const getViewport = () => isMobile() ? 'mobile' : isTablet() ? 'tablet' : 'desktop'

//           onResize :: Function<Viewport> -> Void
// When provided with a function, it will call that function along with
// the current viewport the user is occupying
export const onResize = (f) => {
  // @TODO: Probably should use a debounce or throttle here
  window.addEventListener('resize', () => {
    f(getViewport())
  })
  document.addEventListener('readystatechange', event => {
    // We set timeout 1 here because we are firing just before window.onload
    f(getViewport())
  })
}

let currentViewport = undefined
export const onViewportChange = (f) => {
  window.addEventListener('resize', () => {
    const newViewport = getViewport()
    if (currentViewport == newViewport) return;
    currentViewport = newViewport
    f(currentViewport)
  })
  document.addEventListener('readystatechange', event => {
    setTimeout(f(getViewport()), 1)
  })
}

export default function Responsive() {
  //currentViewport = getViewport()
}
