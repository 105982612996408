export default class ArticlesSearch{
  constructor(header, whitelabels, articlesSearchFilters, documentBodyEl){
    this.toggle = false;
    this.header = header;
    this.body = documentBodyEl;
    this.defaultwidth = whitelabels.viewports.md;
    this.fixedtop = whitelabels.fixedtop;
    this.$searchImgEl = document.querySelector('.searchImg');
    this.$extendsearch = document.querySelector('.extendsearch');
    this.$goimg = document.querySelector('.go-btn-md');
    this.$filters = document.getElementById('articles-mb-filter');
    this.lgFilterEl = document.querySelector('.lg-articles-filters');
    this.$articlesCategories = document.querySelector('.articles-search-form');
    this.$type = document.getElementById('articleslocation');
    this.$filterKey = articlesSearchFilters;
    this.conditionallyHideSearchbar();
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener('resize', event => this.conditionallyHideSearchbar());
    window.addEventListener('click', event => this.clickEventHandler(event));
    window.addEventListener('click', event => this.clickfilterHandler(event));
    window.addEventListener('change', event => this.handleOnChange(event));
  }
  //check if class contains in body
  hasClass = (element) =>{
    return (element.classList.contains('finda-wl') || element.classList.contains('finda-wl-mobile'))
  }
  conditionallyHideSearchbar(){
    this.ww = (document.documentElement.clientWidth)? document.documentElement.clientWidth : document.body.clientWidth;
    if (this.$filterKey.articleslistingEl !== null){
      if (this.ww < this.defaultwidth){
        this.header.style = `height:${this.hasClass(this.body)? this.fixedtop - 16 : this.fixedtop}px`;
      } else {
        this.header.style = '';
      }
    } else {
      (this.lgFilterEl)? this.lgFilterEl.style = '' : '';
      (this.$extendsearch)? this.$extendsearch.style = '' : '';
      (this.$searchImgEl)? this.$searchImgEl.style = '' : '';
    }
  }
  //click Event Handler
  clickEventHandler(event){
    if(this.$filterKey.articleslistingEl !== null && this.ww < this.defaultwidth){
      this.$articlesfilters = $('.lg-articles-filters');
      if(event.target.classList.contains(this.$filterKey.extendsearch) && this.$articlesfilters.css('display') === 'none'){
        this.$goimg.classList.add(this.$filterKey.goButtonEl);
        this.$goimg.style.display = 'block'
        this.$searchImgEl.style = 'visibility:hidden';
        this.$extendsearch.style = 'padding-left:20px';
      }
    }
  }

  //filters
  clickfilterHandler(event){
    if(this.$filterKey.articleslistingEl !== null && this.ww < this.defaultwidth){
      if(event.target.classList.contains(this.$filterKey.articlesmbfilterEl)){
        this.toggle = !this.toggle;
        this.$articlesfilters = $('.lg-articles-filters');
        this.$articlesfilters.toggle('is-visible');
        let filtertext = (this.toggle)? 'Hide Filter' : 'Filter Articles';
        this.$filters.innerText = filtertext;
        this.$goimg.style = 'display:none';
        this.$searchImgEl.style  = this.$extendsearch.style = '';
      }
    }
  }


  //onchange
  handleOnChange(event){
    if(this.$filterKey.articleslistingEl !== null && this.ww < this.defaultwidth){
      let currentValue = event.target.value;
      let parentID = event.target.options[event.target.selectedIndex].dataset.id;
      const searchObj = window.categories_facet.type.filter(x => x[this.$filterKey.articlesfilterkey] === parseInt(parentID));
      if(event.target.classList.contains(this.$filterKey.formselectitemEl)){
        (currentValue != this.$filterKey.allCategories)? this.$articlesCategories.setAttribute('action', window.rnn.site.articlesSearch.actionURL+`${currentValue}/`): this.$articlesCategories.setAttribute('action', window.rnn.site.articlesSearch.actionURL);
        this.customOptionsTypes(searchObj)
      }
    }
  }

  customOptionsTypes(types){
    [...this.$type.options].filter(o => o.value != this.$filterKey.allTypes).forEach(o => o.remove());
    types.forEach((item) => {
      this.$type.options[this.$type.options.length] = new Option(item.child_category_name, item.child_category_slug);
    })
  }

}
