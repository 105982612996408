import $ from 'jquery'
import { whitelabels } from './constants';

$(function () {
    $('.obituaries-list').not('.card-initialized').slick({

      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      centerMode: true,
      variableWidth: true,
      arrows: true,


      responsive: [
        {


            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            },

        }

      ]
    });
    $('.partners-list').not('.card-initialized').slick({


      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: false, //true
      variableWidth: true,
      arrows: true,


      responsive: [
        {


            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },

        }

      ]


    });
    const isMobile = () => window.innerWidth < whitelabels.viewports.md;

    //featured-notices slick
    const loadSlick = () => {
      $('.notices-mb-slick, .fd-mb-slick').not('.card-initialized').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        arrows: true,
        mobilefirst: true,
        responsive: [
          {
            breakpoint: whitelabels.viewports.xl,
            settings: "unslick"
          },
          {
            breakpoint: whitelabels.viewports.md - 1,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
            },
          }
        ]
      });
    }

    //load main logic to initialize slick
    const featuredNoticesSlick = () => isMobile()? loadSlick(): '';

    $(window).on('load resize', function(){
      featuredNoticesSlick();
    })

  });
