// @TODO This could use a cleanup
import $ from 'jquery'
import Hammer from 'hammerjs'

const carouselSelector = ".itemCarousel";

function moveItem($carousel, back){
  var $selectedItem = $carousel.find('.item.selected'),
      gotoTarget;

  if(back){
    gotoTarget = $selectedItem.prev();
    !gotoTarget.length && (gotoTarget = $carousel.find('.item').last());
  }else{
    gotoTarget = $selectedItem.next();
    !gotoTarget.length && (gotoTarget = $carousel.find('.item').first());
  }

  gotoItem(gotoTarget);
}

function gotoItem(item){
  var $item = $(item),
      $carousel = item.closest(carouselSelector),
      currentItemIndex = $carousel.find('.item.selected').index(),
      $carouselItems = $carousel.find('.items').children().removeClass("selected"),
      $countIndicator = $carousel.find('.countIndicator'),
      itemsTotal = $carousel.data("itemsTotal"),
      targetIndex = $item.index(),
      cycleBack = targetIndex < currentItemIndex || targetIndex > itemsTotal / 2 + currentItemIndex,
      animate = $carousel.data("animate"),
      $next;

  function cycleItems(){
    setTimeout(function(){
      if($item.index() !== currentItemIndex){
        if(cycleBack){
          $next = $carousel.find('.items .item').last();
          if (animate) {
            $carousel.find('.items').css({ marginLeft: '-'+ $next.outerWidth(true) + 'px' }).stop(true, true).animate(
              { marginLeft: "0" }, {
                duration: animate,
                start: function () {
                  $carousel.find('.items .item').last().clone(true).prependTo($carouselItems.parent());
                },
                always: function () {
                  $carousel.find('.items .item').last().remove();
                }
              });
          } else {
            $carouselItems.parent().prepend($next);
          }
        }else{
          $next = $carousel.find('.items .item').first();
          if (animate) {
            $carousel.find('.items').stop(true, true).animate({ marginLeft: '-' + $next.outerWidth(true) }, {
              duration: animate,
              start: function () {
                $carousel.find('.items .item').first().clone(true).appendTo($carouselItems.parent());
              },
              always: function () {
                $carousel.find('.items .item').first().remove();
                $(this).css({ marginLeft: 0 });
              }
            });
          } else {
            $carouselItems.parent().append($next);
          }
        }
        if (!animate) {
          cycleItems();
        }
      }
    },50);
  }

  cycleItems();

  $item.addClass("selected");

  if($countIndicator.length){
    $countIndicator.text((($carouselItems.length - 1 + ($item.index() - $carousel.find('.item.first').index() - 1)) % itemsTotal) + 1);
  }
}

function checkWidth($carousel, $items) {
  var numCheck = Math.floor($carousel.width() / $items.first().width()),
      wideEnough = true;

  if ($items.length <= numCheck) {
    wideEnough = false;
    $carousel.find('.carouselControls').addClass('hideControls');
  } else {
    $carousel.find('.carouselControls').removeClass('hideControls');
  }
  return wideEnough;
}


function initItemCarousels(isAdCarousels) {

  var carouselsSelector = carouselSelector + (isAdCarousels ? '.adCarousel:visible' : ':not(.adCarousel)');
  // If there are not enough items to make a nice carousel, make more items!
  var $carousels = $(carouselsSelector);

  $carousels.each(function(){
    var $this = $(this),
        $items = $this.find(".item.adSpaceEmpty").remove().end().find(".item"),
        autoRotate = parseInt($this.data("autorotate"), 10),
        itemsTotal = $items.length,
        $countTotal = $this.find(".countTotal"),
        widthCheck = $this.data('checkwidth');

    $items.first().addClass("first");

    if ($items.filter('.selected').length === 0) {
      $items.first().addClass('selected');
    }

    //putting this on the gallery for later modulo in moveItem countIndicator
    $this.data("itemsTotal",itemsTotal);

    //Populate the total for counter if it exists
    if ($countTotal.length) {
      $countTotal.text(itemsTotal);
    }

    // check if we want to kill the carousel if there aren't enough items for the space
    if (widthCheck === true) {
      var check = checkWidth($this, $items);
      if (check === false) {
        autoRotate = false;
      }
    }

    if($items.length && $this.is(".fancy")){
      if($items.length === 1){
        $this.find(".items").append($items.clone(true), $items.clone(true), $items.clone(true), $items.clone(true)); //Not a fan of this...
      } else if($items.length < 3){
        $this.find(".items").append($items.clone(true), $items.clone(true));
      } else if($items.length < 5){
        $this.find(".items").append($items.clone(true));
      }
      $this.find(".selected").removeClass("selected");
      $this.find(".item").eq(2).addClass("selected");
      gotoItem($items.first());
    }

    if(autoRotate){
      $this.data("lastrotate", new Date());
      setInterval(function(){
        if(!$this.is(".hover") && new Date() > autoRotate + $this.data("lastrotate").getTime()){
          moveItem($this);
        }
      },autoRotate);
    }

    if (rnn.site.isMobile === true) {
      var swipeObject = new Hammer.Manager($this[0]),
          swipeEvent = new Hammer.Swipe({
            velocity: 0.1,
            threshold: 2,
            direction: Hammer.DIRECTION_HORIZONTAL
          });

      swipeObject.add(swipeEvent);

      swipeObject.on('swipeleft swiperight', function (e) {
        var back = true;
        if (e.type === 'swipeleft') {
          back = false;
        }
        moveItem($this, back);
      });
    }
  });
}

function initAdCarousels() {
  if ($('html').hasClass('advertising-loaded')) {
    initItemCarousels(true);
  } else {
    setTimeout(initAdCarousels, 100);
  }

}

// Initialise Carousels
export default function ItemCarousel() {
  initItemCarousels();
  initAdCarousels();

  // Bind conrols and add IE7 support
  $(document)
    .on('click', carouselSelector + ' .carouselControls', function(){
      var $carousel = $(this).closest(carouselSelector);

      if($(this).is('.prev')){
        moveItem($carousel, true);
      }else{
        moveItem($carousel);
      }
      $carousel.data("lastrotate", new Date());
    })
    .on('mouseenter', carouselSelector, function(){
      $(this).addClass("hover");
    })
    .on('mouseleave', carouselSelector, function(){
      $(this).removeClass("hover");
    });
}
