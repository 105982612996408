import m from 'mithril'
import u from 'umbrellajs'
import Cookies from 'js-cookie'
import { tryCatch } from 'rambda'
import { onResize } from '../Responsive'

// Safe functions for JSON.parse with default values
const parseObject = tryCatch(JSON.parse, {})
const parseArray = tryCatch(JSON.parse, [])
const humanize = string => string.charAt(0).toUpperCase() + string.slice(1)

//              AdminComponent :: User -> MithrilComponent
export function AdminComponent(user){
  const userName = user.first_name ? user.first_name : user.username
  const noCacheURL = window.location.pathname + '?nocache=' + (Math.floor(Math.random() * 999999) + 1)
  const adTestURL = window.location.pathname + '?ad_test_debug=1'

  // Get a unique "Set" of overlays types (models) that the user has access to
  // @TODO: We probably should reduce into uniq instead? or use pipe?
  const overlayTypes = [... new Set(u('*[data-overlay-marker]').nodes
    .map(n => parseObject(u(n).data('overlay-marker')).content_type))]
        .sort((a,b) => a < b ? -1 : 1)
        .filter(type => user.allowed_models.includes(type))

  // Get the primary page marker if we have one
  const primaryMarker = u('*[data-overlay-marker]').map(n => {
    const marker = parseObject(u(n).data('overlay-marker'))
    // Ensure the user can actually edit the model before presenting the option
    return marker.is_primary && user.allowed_models.includes(marker.content_type)
      ? marker : false
  }).filter(d => d !== false).first() || null

  // @TODO: should probably do this via a lifecycle and check vNode usage to
  // ensure it's being done correctly. Its not super important for this module
  // due to it's relativly simple mutable state but would be good to get right here.
  const redraw = () => {
    overlays = getOverlays()
    m.redraw()
  }

  const isWithinParent = (child) => {
    const parent = u(child).closest('section[data-overlay-marker]')
    const outer = (parent.length > 0 ? parent.first() : child.parentElement).getBoundingClientRect()
    // const outer = u(child).closest('*[data-over]').parentElement.getBoundingClientRect()
    const inner = child.getBoundingClientRect()
    if( outer.bottom <= inner.top || outer.right <= inner.left ){ return false }
    return true
  }

  // Returns an array of overlay data enhanced with styles
  const getOverlays = () => u('*[data-overlay-marker]').map(marker => {
    return {
      ...parseObject(u(marker).data('overlay-marker')),
      isVisible: (isWithinParent(marker) && (marker.offsetWidth > 0 || marker.offsetHeight > 0)),
      styles: {
        width: `${marker.clientWidth - 4}px`,
        height: `${marker.clientHeight - 4}px`,
        top: `${marker.getBoundingClientRect().top + (window.scrollY || window.pageYOffset) + 2}px`,
        left: `${marker.getBoundingClientRect().left + 2}px`,
      }
    }
  }).nodes.filter(d => d.isVisible)
  let overlays = getOverlays()

  // Is the adminUI widget currently showing to the user
  let widgetOpen = Cookies.get('adminUIOverlays') === 'open'
  const toggleWidget = () => {
    widgetOpen = !widgetOpen
    Cookies.set('adminUIOverlays', widgetOpen ? 'open' : '', { path: "/", sameSite: 'strict' })
    redraw()
  }

  // Allows the user to toggle the functionality of overlays on and off
  let overlaysEnabled = localStorage.getItem('adminUI.overlaysEnabled') === 'true'
  const toggleOverlays = () => {
    overlaysEnabled = !overlaysEnabled
    localStorage.setItem('adminUI.overlaysEnabled', overlaysEnabled)
    redraw()
  }

  // A list containing the currently selected overlays
  let overlaysSelected = parseArray(localStorage.getItem('adminUI.overlaysSelected')) || []
  const toggleOverlay = name => {
    overlaysSelected = overlaysSelected.includes(name)
      ? overlaysSelected.filter(o => o !== name)
      : overlaysSelected.concat(name)
    localStorage.setItem('adminUI.overlaysSelected', JSON.stringify(overlaysSelected))
    redraw()
  }

  // Redraw Events
  onResize(redraw)

  // console.log('state', {overlaysEnabled, overlaysSelected})
  // Mithril object representation
  return {
    view: () => (
      <>
        <div id="admin-ui" class={widgetOpen ? 'open' : ''}>
          <button id="admin-ui-toggle" onclick={toggleWidget} />
          <div class="admin-ui-widget user-widget">
            <div class="admin-header" >Hi, {userName}</div>
            <div class="admin-body">
              <a href="/admin/" target="_blank">Admin Site</a>
              <a href={noCacheURL}>Bust Cache</a>
              <a href={adTestURL}>Debug Ads</a>
            </div>
          </div>
          <div class="admin-ui-widget overlay-widget">
            <div class="admin-header">
              <label>
                <input name="toggle-overlay" type="checkbox" checked={overlaysEnabled} onchange={toggleOverlays} />
                Overlays
                <a class="external" target='_blank' href={ window.location.pathname + '?overlays=1' }>&#128279;</a>
              </label>
            </div>
            <div class="admin-body">
              { primaryMarker
                ? (<a target="_blank" href={primaryMarker.edit_url}>Edit this {primaryMarker.content_type}</a>)
                : null }
              <ul class="overlay-toggles">
                {overlayTypes.map(overlay => (
                  <li>
                    <label>
                      <input type="checkbox" name='overlay' value={overlay}
                             checked={overlaysSelected.includes(overlay)}
                             onchange={() => toggleOverlay(overlay)} />
                      {humanize(overlay)}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div id="admin-overlays">
          { overlays.map(({edit_url, content_type, label, styles }) => (
            <div class={overlaysEnabled && overlaysSelected.includes(content_type) ? 'visible' : ''} data-overlay={content_type} style={styles}>
              <a href={edit_url} target="_blank" >{label}<span>{content_type}</span></a>
            </div>
          ))}
        </div>
      </>
    )
  }
}

function AdminUI(){
  window.rnn.modules.user.get(user => {
    // While in production, only staff can see this.
    if(!user.is_staff){ return; }
    // Cleanup any old menu setup if it exists
    // @TODO: bind to unload instead
    u('#admin-ui-wrapper').remove()

    // Create and mount the admin ui
    const $uiWrap = u('<div id="admin-ui-wrapper" />').first()
    m.mount($uiWrap, AdminComponent(user))
    u(document.body).append($uiWrap)
  })
}

export default {
  init: AdminUI
}
