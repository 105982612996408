import $ from 'jquery'

$(function () {
  $('.listing-related-notices').not('.card-initialized').slick({
    dots: true,
    slidesToShow: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
});
