export default class ObituariesSearch{
  constructor(header, whitelabels, obPlaceholder, documentBodyEl){
    this.header = header;
    this.body = documentBodyEl;
    this.placeholder = obPlaceholder;
    this.lg = whitelabels.viewports.lg;
    this.fixedtop = whitelabels.fixedtop;
    this.defaultwidth = whitelabels.viewports.md;
    this.$searchImgEl = document.querySelector('.searchImg');
    this.$extendsearch = document.querySelector('.extendsearch');
    this.conditionallyHideSearchbar();
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener('resize', event => this.conditionallyHideSearchbar());
    window.addEventListener('click', event => this.clickEventHandler());
  }

  getWindowWidth(){
    return (typeof (window.innerWidth) == 'number')? window.innerWidth : document.documentElement.clientWidth
  }

  //check if class contains in body
  hasClass = (element) =>{
    return (element.classList.contains('finda-wl') || element.classList.contains('finda-wl-mobile'))
  }

  conditionallyHideSearchbar(){
    let ww = this.getWindowWidth();
    if(document.querySelector('.obituaries-listing') !== null){
      (ww < this.defaultwidth)? this.header.style = `height:${this.hasClass(this.body)? this.fixedtop - 16 : this.fixedtop}px` : this.loadDefaults();
      (ww >= this.defaultwidth && ww < this.lg)? this.replacePlaceholder(this.placeholder.lg) : this.replacePlaceholder(this.placeholder.default);
    }
  }

  replacePlaceholder(text){
    this.$extendsearch.setAttribute('placeholder',text)
  }

  loadDefaults(){
    this.header.style = this.$searchImgEl.style = this.$extendsearch.style = '';
  }

  clickEventHandler(){
    let ww = this.getWindowWidth();
    if(document.querySelector('.obituaries-listing') !== null && ww < this.defaultwidth && this.$extendsearch != null && this.$extendsearch === document.activeElement){
      this.$goButton = $('.go-btn-md');
      this.$goButton.addClass('goButton-visible');
      this.$searchImg = document.querySelector('.searchImg');
      this.$searchImg.style = 'display:none';
      this.$extendsearch.style = 'padding-left:20px';
    }
  }
}
