import m from 'mithril'
import u from 'umbrellajs'

const prevent = e => { e.preventDefault();e.stopPropagation();return false; }

// A base mithril component that is meant to be extended by other mithril components
// to provide a functional dropdown with a provided datasource.
export default function Dropdown(initialState){
  let state = {
    id: '',
    records: [],
    recordFocus: false,
    defaultValue: '',
    value: '',
    after: null,
    loading: false,
    minInput: 2,
    onSelection: (event, id, value) => console.debug('noop', {event, id, value}),
    onChange: (event) => console.debug('noop', {event}),
    ...initialState.attrs,
  }

  // Helpers
  const highlight = text => state.value.length > 0 && !state.id
        ? text.replace(new RegExp(state.value, 'ig'), '<b>$&</b>')
        : text
  const recordClass = id => id === state.id || ( !state.id && id === state.recordFocus?.id )
        ? 'record active'
        : 'record'

  // Events
  const onKeyPress = async function(e){
    // Override form submit for selection
    if(e.key === 'Enter'){
      prevent(e)
      state.value = state.recordFocus.value
      onSelection(state.recordFocus.id, state.recordFocus.value)(e)
    }
  }
  const onKeyUp = async function(e){
    // Arrow selection
    if(['ArrowDown', 'ArrowUp'].includes(e.key)){
      const clamp = i => Math.max(0, Math.min(i, state.records.length - 1))
      const record = state.records[clamp(
        state.records.indexOf(state.recordFocus) + (e.key === 'ArrowUp' ? -1 : 1)
      )]
      state.recordFocus = record
      return prevent(e)
    }
    // Standard Input change, update the value state and clear results
    if(e.target.value === state.value || e.target.value === state.id) return m.redraw();
    state.recordFocus = false
    state.id = ""
    state.value = e.target.value
    state.records = []
    // If we meet our acceptable params, we will allow the parent component to read the change
    // and update the data accordingly
    if (state.value.length < state.minInput) return m.redraw();
    state.loading = true
    state.onChange(e)
  }
  const onSelection = function(id, value){
    return function(event){
      // Grab the input element and set the input value and focus before we pass off to the parent
      const target = u(event.target).closest('div').find('input[type=text]').first()
      if(target && target.tagName === 'INPUT'){
        target.value = value
        target.focus()
      }
      state.id = id
      state.value = value
      state.onSelection(event, id, value)
    }
  }

  return {
    onbeforeupdate: function({attrs}) {
      state = {
        ...state,
        // onChange: attrs.onChange, // Antipatern
        records: attrs.records,
        loading: typeof attrs.loading === 'boolean' ? attrs.loading : state.loading,
      }
      return true
    },
    view: ({attrs}) => (
      <>
        <input type='hidden' name={state.label + '_id'} value={state.id} />
        <input type='text' autocomplete="off"
               name={state.label}
               onkeypress={onKeyPress}
               onkeyup={onKeyUp}
               defaultValue={attrs.value || state.defaultValue} />
        <ul class="dropdown" tabindex={-1}>
          { state.loading
            ? (<li class="no-select">Loading..</li>)
            : attrs.records.length === 0 && state.value.length > 0 && !state.id
              ? (<li class="no-select">No Results</li>)
              : null }
          { attrs.records.map(({id, value}) => (
            <li key={id} class={ recordClass(id) } onclick={onSelection(id, value)}>
              { m.trust(highlight(value)) }
            </li>
          )) }
          { state.after }
        </ul>
      </>
    )
  }
}
