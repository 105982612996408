import $ from 'jquery';
import DOMPurify from 'dompurify';

export default function makeElementScroll(selector) {
  if(selector.fdElement != null){
    let currentElement = selector.elementlist;
    if(currentElement.lg != null && currentElement.lg.classList.contains(selector.classvariables.lg) && window.getComputedStyle(currentElement.lg).display != 'none'){
      currentElement.lg.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'start' });
    }else if(currentElement.md != null && currentElement.md.classList.contains(selector.classvariables.md) && window.getComputedStyle(currentElement.md).display != 'none'){
      currentElement.md.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
    }else{
      (currentElement.success != null) ? currentElement.success.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }) : '';
    }
  }
}

$(document).on('change', "#fdstatedropdown", function(){
  const state_val=$(this).val();
  if(state_val) {
    $.ajax({
      type:"GET",
      url : window.rnn.site.fdStateFilterUrl,
      data : {state_val:state_val},
      cache: false,
      success : function(response) {
          $('.fd-section-replace').html(
            DOMPurify.sanitize(response, { USE_PROFILES: { html: true } })
          )
      }
    });
  }
});
